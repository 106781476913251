import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'
import useGetProfile from '../hooks/query/useGetProfile'
import { getProfile } from '../service/user'
import { UserInfo } from 'os'
import useUserStore from '../store/useUserStore'
import { useNavigate } from 'react-router-dom'
import { WelcomeBox } from '../components/freePlan/WelcomeBox'
import { signInBtnClick } from '../utils/metaAd'
import { getLocalStorage } from '../utils/common'

export const SignIn = () => {
  const userInfo = useUserStore((state: any) => state.userInfo)
  const navigate = useNavigate()
  const onSignIn = async () => {
    const utm_source = getLocalStorage('utm_source')
    const utm_campaign = getLocalStorage('utm_campaign')
    const userAgent: string =
      navigator.userAgent || navigator.vendor || (window as any).opera
    const state = JSON.stringify({
      terms: 'signIn',
      marketing: '',
      utm_source: utm_source ? utm_source : '',
      utm_campaign: utm_campaign ? utm_campaign : '',
      userAgent: userAgent ? userAgent : '',
    })

    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google?state=${state}`
  }
  return (
    <div className="pt-[60px] max-w-[816px]  h-min-[100vh] my-0 mx-auto flex flex-col items-center gap-[40px]">
      <div className="flex items-center justify-center">
        <p className="text-[36px] md:text-[48px] font-bold flex items-center justify-center">
          <button
            onClick={() => {
              navigate('/sign-up')
            }}
          >
            <span className="text-[#B6BBC6]">회원가입</span>
          </button>
          <span className="text-[#B6BBC6] mx-[20px] md:mx-[40px]">|</span>
          <button
            onClick={() => {
              navigate('/sign-in')
            }}
          >
            <span className="">로그인</span>
          </button>
        </p>
      </div>
      <div className="flex flex-col justify-center items-center gap-[16px]">
        <p className="font-bold text-[20px] md:text-[32px]">
          패스트컷 로그인 계정이 있으신가요?
        </p>
        <p className="text-[18px]">
          회원가입하신 구글 계정으로 로그인 해주세요!
        </p>
      </div>

      <div className="w-[335px] h-[64px]  flex items-center justify-center ">
        <button
          className={`text-[18px] flex justify-between border border-[#D9DEE8] rounded-[10px] bg-[#FFFFFF] text-[#222222]`}
          onClick={onSignIn}
        >
          <div className="w-[66px] h-[64px] border-r flex items-center justify-center">
            <img
              src="assets/img/logo_google.png"
              alt="google_log"
              width={24}
              height={24}
            />
          </div>
          <div className="w-[220px] md:w-[270px] h-[64px] flex items-center justify-center">
            <span>구글 계정으로 계속하기</span>
          </div>
        </button>
      </div>

      <WelcomeBox />
    </div>
  )
}
