import React, { useEffect, useState } from 'react'
import { My } from '../components/My'

import { isMobile } from 'react-device-detect'

import useConfirmPayment from '../hooks/query/useConfirmPayment'
import useGetMePlan from '../hooks/query/useGetMePlan'
import { PriceGuide } from './PriceGuide'
import { useNavigate } from 'react-router-dom'
import useUserStore from '../store/useUserStore'
import { Spinner } from '../components/atom/Spinner'
import useGetMe from '../hooks/query/useGetMe'

import { getUtmLocalStorage, showErrorBox, UserType } from '../utils/common'
import classNames from 'classnames'
import { AfterGuideCircle } from '../components/freePlan/GuideCircle'
import { PromotionCircle } from '../components/upgrade/PromotionCircle'
import { planPurchaseBtnClick, purchasePlan } from '../utils/metaAd'
import { useSaveUtm } from '../hooks/query/useSaveUtm'

export const MyPayments = () => {
  const navigate = useNavigate()
  const { mutate: saveUtmMutate } = useSaveUtm()
  const { data: userPlanInfo, refetch } = useGetMePlan()
  const { refetch: meRefetch } = useGetMe()
  const userInfo = useUserStore((state: any) => state.userInfo)
  const urlParams = new URLSearchParams(window.location.search)
  const paymentKey = urlParams.get('paymentKey')
  const orderId = urlParams.get('orderId')
  const amount = urlParams.get('amount')

  const { mutate: confirmPaymentMutate, isPending } = useConfirmPayment()

  useEffect(() => {
    if (paymentKey && orderId && amount) {
      const confirmPaymentParams = {
        paymentKey,
        orderId,
        amount: Number(amount),
      }
      confirmPaymentMutate(confirmPaymentParams, {
        onSuccess: (data) => {
          const utmData = getUtmLocalStorage()
          if (utmData?.utmSource) {
            utmData.trackType = 'PURCHASE_PLAN'
            saveUtmMutate(utmData, {
              onSuccess: () => {},
              onError: () => {},
            })
            purchasePlan()
          } else {
            planPurchaseBtnClick()
          }

          navigate('/my-payments')
        },
        onError: () => {
          navigate('/payment-fail?path=price-guide')
        },
      })
    }
  }, [paymentKey, orderId, amount])

  useEffect(() => {
    if (isPending) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
      meRefetch()
      refetch()
    }
  }, [isPending])

  return (
    <div className=" relative md:pt-[60px] pt-[24px] md:max-w-[816px] md:min-y-[100vh] max-w-[375px] my-0 mx-auto flex flex-col px-[16px]">
      <div className="md:w-full w-[343px]">
        <div className="">
          <My>
            <p>결제 플랜 관리</p>
          </My>
          <div className="flex justify-center">
            <p className="font-bold md:text-[48px] text-[24px]">
              결제 플랜 관리
            </p>
          </div>
          {showErrorBox(userInfo)}
          <PromotionCircle
            userInfo={userInfo}
            planId={userInfo?.subscriptionType?.planId}
          />
          <div
            className={classNames(
              'bg-[#F6F8FB]  w-[343px] md:w-full rounded-[40px] flex flex-col text-[18px] gap-[18px]  mt-[24px] px-[24px] py-[32px]',
              {
                'border-[2px] border-href':
                  userInfo?.userType === UserType.체험,
              }
            )}
          >
            <div className="plan flex">
              <p className="mr-[16px] font-bold">계정</p>

              <p className="mr-[16px]">
                <span>{userInfo?.userInfo?.name}</span>
                <span>{` (${userInfo?.userInfo?.email})`}</span>
              </p>
            </div>
            <div className="plan flex ">
              <p className="mr-[16px] font-bold">플랜</p>

              <p className="mr-[16px]">
                <span
                  className={classNames('font-bold', {
                    'text-main': userInfo?.userType !== UserType.체험,
                    'text-href': userInfo?.userType === UserType.체험,
                  })}
                >
                  {userPlanInfo?.subscriptions?.planName
                    ? userPlanInfo?.subscriptions?.planName
                    : '아직 결제한 플랜이 없어요!'}
                </span>{' '}
                {userPlanInfo?.subscriptions &&
                userPlanInfo?.planPrice?.planPrice[0].subscriptionType &&
                userPlanInfo?.planPrice?.planPrice[0].subscriptionType ===
                  'monthly'
                  ? userInfo?.userType !== UserType.체험 && '1개월'
                  : ''}
                {userPlanInfo?.subscriptions &&
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType &&
                  userPlanInfo?.planPrice?.planPrice[0].subscriptionType ===
                    'annual' &&
                  '1년'}
              </p>
            </div>
            {userInfo?.userType !== UserType.체험 && (
              <div className="date flex">
                <p className="mr-[16px] font-bold">기간</p>

                {userPlanInfo?.subscriptions?.currentMonth && (
                  <p className="text-main font-bold mr-[16px] text-[18px]">
                    {userPlanInfo?.subscriptions?.currentMonth}
                  </p>
                )}

                <p className="">
                  {userPlanInfo?.subscriptions &&
                    userPlanInfo?.subscriptions.subscriptionType ===
                      'monthly' &&
                    userPlanInfo?.subscriptions?.startDate &&
                    `${userPlanInfo?.subscriptions.startDate} ~ ${userPlanInfo?.subscriptions.endDate}`}

                  {userPlanInfo?.subscriptions &&
                    userPlanInfo?.subscriptions.subscriptionType === 'annual' &&
                    userPlanInfo?.subscriptions?.monthStart &&
                    `${userPlanInfo?.subscriptions.monthStart} ~ ${userPlanInfo?.subscriptions.monthEnd}`}

                  {!userPlanInfo?.subscriptions && '-- ~ --'}
                </p>
              </div>
            )}

            <div className="status flex">
              <p className="mr-[16px] font-bold">현황</p>
              <p className="mr-[16px]">
                <span
                  className={classNames('font-bold', {
                    'text-main': userInfo?.userType !== UserType.체험,
                    'text-href': userInfo?.userType === UserType.체험,
                  })}
                >
                  요청{' '}
                  {userPlanInfo?.usage?.usedCount
                    ? userPlanInfo?.usage?.usedCount
                    : 0}
                  개
                </span>{' '}
                / 최대{' '}
                {userPlanInfo?.usage?.totalLimit
                  ? userPlanInfo?.usage?.totalLimit
                  : 0}
                개
              </p>
            </div>
            {/* <div>
              <div className="flex gap-[40px] w-[294px] md:w-[736px] bg-black h-[24px] relative border rounded-[50px] overflow-hidden mb-[30px]">
                {createBarEl(
                  Number(
                    userPlanInfo?.usage?.totalLimit
                      ? userPlanInfo?.usage?.totalLimit
                      : 0
                  ),
                  Number(
                    userPlanInfo?.usage?.usedCount
                      ? userPlanInfo?.usage?.usedCount
                      : 0
                  )
                )}
              </div>
              <div className="relative w-[294px] md:w-[736px] text-[#8F929B] font-bold">
                <p className="absolute text-[14px] bottom-2 left-0">0</p>

                <p className="absolute text-[14px] bottom-2 right-0">
                  {userPlanInfo?.usage?.totalLimit}
                </p>
              </div>
            </div> */}
          </div>
        </div>
        {userInfo?.userType === UserType.체험 && (
          <>
            <div className="mt-[32px]">
              {' '}
              <AfterGuideCircle />{' '}
            </div>
          </>
        )}

        {userPlanInfo?.subscriptions ? (
          <>
            <div
              className={classNames(
                'mt-[32px] p-[32px]  rounded-[20px] border-[2px] ',
                {
                  'border-main bg-[#E9F8F9]':
                    userPlanInfo?.subscriptions.planName !== '체험',
                  'border-href bg-[#E9EDF9]':
                    userPlanInfo?.subscriptions.planName === '체험',
                }
              )}
            >
              <div className="flex flex-col justify-center items-center gap-[12px]">
                <p
                  className={classNames('font-bold text-[24px] ', {
                    'text-main':
                      userPlanInfo?.subscriptions.planName !== '체험',
                    'text-href':
                      userPlanInfo?.subscriptions.planName === '체험',
                  })}
                >
                  {userPlanInfo?.subscriptions.planName === '체험'
                    ? '체험하기'
                    : `${userPlanInfo?.subscriptions.planName} 플랜`}
                </p>
                {/* <p>
                  <span className="font-bold text-[32px]">
                    {userPlanInfo?.planPrice?.planPrice?.length
                      ? userPlanInfo?.planPrice?.planPrice[0]?.price.toLocaleString() +
                        '원'
                      : '0원'}
                  </span>
                  {!!userPlanInfo?.planPrice.planPrice[0] &&
                  userPlanInfo?.planPrice?.planPrice[0] &&
                  userPlanInfo?.planPrice?.planPrice[0]?.subscriptionType ===
                    'monthly' ? (
                    <span className="text-[18px]">/월(VAT 별도)</span>
                  ) : (
                    <span className="text-[18px]">/1년(VAT 별도)</span>
                  )}
                </p> */}
              </div>

              <div className="flex mt-[32px]">
                {isMobile ? (
                  <>
                    <div className="w-full flex flex-col gap-[24px]">
                      {userInfo?.userType !== UserType.체험 && (
                        <p className="font-bold text-[18px] flex items-center gap-[5px]">
                          <img
                            src={
                              userPlanInfo?.subscriptions.planName !== '체험'
                                ? 'assets/img/check_circle.png'
                                : 'assets/img/check_circle_blue.png'
                            }
                            alt="check_circle"
                            width={24}
                            height={24}
                          />
                          {userInfo?.subscriptions?.subscriptionType ===
                          'annual' ? (
                            <>12개월 사용</>
                          ) : (
                            <>1개월 사용</>
                          )}
                        </p>
                      )}

                      {userPlanInfo?.planDetail.planDetail.map((el, index) => {
                        return (
                          <>
                            {el.type === 'feature' && (
                              <p className="font-bold text-[18px] flex items-center gap-[5px]">
                                <img
                                  src={
                                    userPlanInfo?.subscriptions.planName !==
                                    '체험'
                                      ? 'assets/img/check_circle.png'
                                      : 'assets/img/check_circle_blue.png'
                                  }
                                  alt="check_circle"
                                  width={24}
                                  height={24}
                                />
                                {el.name}
                              </p>
                            )}
                          </>
                        )
                      })}
                      {userPlanInfo?.planDetail.planDetail.map((el, index) => {
                        return (
                          <>
                            {el.type === 'function' && (
                              <p className="font-bold text-[18px] flex items-center gap-[5px]">
                                <img
                                  src={
                                    userPlanInfo?.subscriptions.planName !==
                                    '체험'
                                      ? 'assets/img/check_circle.png'
                                      : 'assets/img/check_circle_blue.png'
                                  }
                                  alt="check_circle"
                                  width={24}
                                  height={24}
                                />
                                {el.name}
                              </p>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="w-[50%] flex flex-col gap-[24px]">
                      {userInfo?.userType !== UserType.체험 && (
                        <p className="font-bold text-[18px] flex items-center gap-[5px]">
                          <img
                            src={
                              userPlanInfo?.subscriptions.planName !== '체험'
                                ? 'assets/img/check_circle.png'
                                : 'assets/img/check_circle_blue.png'
                            }
                            alt="check_circle"
                            width={24}
                            height={24}
                          />
                          {userInfo?.subscriptions?.subscriptionType ===
                          'annual' ? (
                            <>12개월 사용</>
                          ) : (
                            <>1개월 사용</>
                          )}
                        </p>
                      )}
                      {userPlanInfo?.planDetail.planDetail.map((el, index) => {
                        return (
                          <>
                            {el.type === 'feature' && (
                              <p className="font-bold text-[18px] flex items-center gap-[5px]">
                                <img
                                  src={
                                    userPlanInfo?.subscriptions.planName !==
                                    '체험'
                                      ? 'assets/img/check_circle.png'
                                      : 'assets/img/check_circle_blue.png'
                                  }
                                  alt="check_circle"
                                  width={24}
                                  height={24}
                                />
                                {el.name}
                              </p>
                            )}
                          </>
                        )
                      })}
                    </div>
                    <div className="w-[50%] flex flex-col gap-[24px]">
                      {userPlanInfo?.planDetail.planDetail.map((el, index) => {
                        return (
                          <>
                            {el.type === 'function' && (
                              <p className="font-bold text-[18px] flex items-center gap-[5px]">
                                <img
                                  src={
                                    userPlanInfo?.subscriptions.planName !==
                                    '체험'
                                      ? 'assets/img/check_circle.png'
                                      : 'assets/img/check_circle_blue.png'
                                  }
                                  alt="check_circle"
                                  width={24}
                                  height={24}
                                />
                                {el.name}
                              </p>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <PriceGuide />
        )}

        {/* 분기에 따라서 보여줘야할 화면이 달라짐 */}
      </div>
      {isPending && (
        <>
          {' '}
          <Spinner />
        </>
      )}
    </div>
  )
}
