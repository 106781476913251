import { ShadowBox } from '../components/ShadowBox'
import { Input } from '../components/atom/Input'
import { useForm, useFieldArray, Controller } from 'react-hook-form'

import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useUserStore from '../store/useUserStore'
import usePostList from '../hooks/query/userPostList'
import { Modal } from '../components/layout/Modal'

import { useNavigate } from 'react-router-dom'
import { useGetProducts } from '../hooks/query/useGetProducts'
import { CircularCheckbox } from '../components/CircularCheckbox'
import '../App.css'
import useGetBgm from '../hooks/query/useGetBgm'
import useGetTransition from '../hooks/query/useGetTransition'
import useGetVoice from '../hooks/query/useGetVoice'
import { useGetLongTypeTone } from '../hooks/query/useGetTone'
import useGetPrompt from '../hooks/query/useGetPrompt'
import TooltipIcon from '../components/TooltipIcon'
import { Usage } from '../service/user'

import {
  getLocalStorage,
  LOCAL_THUMBNAIL_INFO,
  PREV_THUMBNAIL_INIT,
  removeLocalStorage,
  saveLocalStorage,
  showErrorBox,
  THUMBNAIL_INFO,
  UserType,
} from '../utils/common'

import { CSSTransition } from 'react-transition-group'
import useGetThumbnail from '../hooks/query/useGetThumbnail'
import { thumbnailOptions, ThumbnailOptionType } from '../dummy/data'
import { GuideToUse } from '../components/GuideToUse'

import { Thumbnail, ThumbnailInfo } from '../components/Thumbnail'
import { MobileThumbnail } from '../components/MobileThumbnail'
import {
  BeforeGuideCircle,
  AfterGuideCircle,
} from '../components/freePlan/GuideCircle'
import { WelcomeBox } from '../components/freePlan/WelcomeBox'
import { GuideModal } from '../components/freePlan/GuideModal'
import { PromotionCircle } from '../components/upgrade/PromotionCircle'
import { PlanTag } from '../components/planTag'
import SubtitleToolltip from '../components/shortvideo/SubtitleTooltip'
import useGetSubtitle from '../hooks/query/useGetSubtitle'
import { longFormCreateBtnClick } from '../utils/metaAd'

export const Video = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const isTime = urlParams.get('time')
  const guide_sample = getLocalStorage('guide_sample')
  const [isSample, setSample] = useState(guide_sample)
  // bgm / voice / transition 불러오기
  const URL_PATTERN =
    /^https:\/\/(s\.click\.aliexpress\.com|link\.coupang\.com\/a)/

  const PRODUCT_NAME_PATTERN = /^[^<>\uD800-\uDFFF]*$/

  const videoEditorData = getLocalStorage('videoEditorData')

  const { data: bgmList, refetch: bgmRefetch } = useGetBgm()
  const { data: transitionList, refetch: transitonRefetch } = useGetTransition()
  const { data: voiceList, refetch: voiceRefetch } = useGetVoice()
  const { data: toneList, refetch: toneRefetch } = useGetLongTypeTone('fastcut')
  const { data: promptList, refetch: promptRefetch } = useGetPrompt()
  const { data: thumbnailList, refetch: thumbnailRefetch } = useGetThumbnail()
  const { data: subTitleList } = useGetSubtitle()

  // sample가져오기
  const { data: products } = useGetProducts()

  const navigate = useNavigate()

  const userInfo = useUserStore((state: any) => state.userInfo)

  const userCheck = () => {
    if (!userInfo) {
      return navigate('/sign-up')
    }
    if (
      !userInfo?.usage ||
      (userInfo?.userType === UserType.체험 && !userInfo?.usage.available)
    ) {
      navigate('/price-guide')
      return
    }
  }

  const { isPending, mutate } = usePostList()
  const [isGuideModal, setGuideModal] = useState<boolean>(false)
  const [isRequest, setRequest] = useState<boolean>(true)
  const [isMobileTModal, setMobileTModal] = useState<boolean>(false)
  const [saveSuccessModal, setSaveSuccessModal] = useState<boolean>(false)
  const [essentialCheck, setEssentialCheck] = useState<boolean>(false)
  const [isYoutube, setIsYoutube] = useState<boolean>(false)
  const [isThumbnailModal, setThumbnailCheckModal] = useState<boolean>(false)
  const [prevThumbnail, setPrevThumbnail] = useState(PREV_THUMBNAIL_INIT)
  const [editThumbnail, setEditThumbnail] = useState<boolean>(false)
  const [thumbnailInfo, setThumbnailInfo] =
    useState<ThumbnailInfo>(THUMBNAIL_INFO)

  const [errorText, setErrorText] = useState<string>('')

  const [tooltipStatus, setTooltipStatus] = useState<boolean>(false)
  const [usage, setUsage] = useState<Usage>()

  const [mobileStatus, setMobileStatus] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState('')

  // inputRef
  const mainTitleInputRef = useRef<null | HTMLInputElement>(null)
  const productsRef = useRef<HTMLInputElement | null>(null)

  const [subtitleTooltip, setSubtitleTooltip] = useState<boolean>(false)

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    setError,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mainTitle: '',
      voiceId: 0,
      bgmId: 0,
      transitionId: 0,
      aiToneTypeId: 0,
      aiPromptTypeId: 0,
      thumbnailId: 0,
      subtitleId: 0,
      products: [
        { link: '', productName: '', files: [], selectedFiles: [] },
        { link: '', productName: '', files: [], selectedFiles: [] },
        { link: '', productName: '', files: [], selectedFiles: [] },
      ],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
    rules: {
      maxLength: 5,
    },
  })

  const initialThumbnailOption: ThumbnailOptionType = {
    name: 'thumbnail_01',
    titleList: [
      {
        title: {
          titleName: 'title1',
          titleContent: '',
          placeholder: '',
          maxLength: 0,
          color: '#FFFFFF',
        },
        position: '',
      },
      {
        title: {
          titleName: 'title2',
          titleContent: '',
          placeholder: '',
          maxLength: 0,
          color: '#FFFFFF',
        },
        position: '',
      },
      {
        title: {
          titleName: 'title3',
          titleContent: '',
          placeholder: '',
          maxLength: 0,
          color: '#FFFFFF',
        },
        position: '',
      },
    ],
  }
  const [thumbnailType, setThumbnailType] = useState<ThumbnailOptionType>(
    initialThumbnailOption
  )
  const translatePlanName = (planName: string) => {
    let tPlanName = ''
    switch (planName) {
      case '라이트':
        tPlanName = 'LIGHT'
        break
      case '프로':
        tPlanName = 'PRO'
        break
      case '프리미엄':
        tPlanName = 'PREMIUM'
        break
    }
    return tPlanName
  }

  const onSubmit = async (data: any) => {
    if (!userInfo) {
      return navigate('/sign-up')
    }
    setRequest(false)
    let {
      mainTitle,
      products,
      voiceId,
      bgmId,
      transitionId,
      aiPromptTypeId,
      aiToneTypeId,
      thumbnailId,
      subtitleId,
    } = data

    const prevInfo = {
      voiceId,
      bgmId,
      transitionId,
      aiPromptTypeId,
      aiToneTypeId,
      subtitleId,
    }
    saveLocalStorage('videoEditorData', prevInfo)
    mainTitle = mainTitle.trim()

    const products_trim = []
    for (const product of products) {
      if (product.link && product.productName) {
        products_trim.push({
          link: product.link.trim(),
          productName: product.productName.trim(),
          files: product.selectedFiles,
        })
      }
    }
    if (
      thumbnailId === 0 ||
      (thumbnailInfo.title1 === '' &&
        thumbnailInfo.title2 === '' &&
        thumbnailInfo.title3 === '')
    ) {
      setErrorText('썸네일 내용을 작성해 주세요!')
      if (mainTitleInputRef.current) {
        mainTitleInputRef.current.focus()
        mainTitleInputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      setEssentialCheck(true)
      return
    }
    const subtitleInfo = subTitleList?.filter((el) => {
      if (el.id === subtitleId) {
        return el
      }
    })
    const videoInfo = {
      mainTitle,
      products: products_trim,
      voiceId,
      bgmId,
      transitionId,
      aiPromptTypeId,
      aiToneTypeId,
      thumbnailId,
      subtitleId,
      thumbnailInfo,
      check: isRequest,
    }

    const formData = new FormData()

    // Append the non-file data
    // JSON 객체를 FormData로 변환하여 추가하는 재귀 함수
    function appendFormData(
      data: string | object | null,
      rootName = '',
      formData = new FormData()
    ) {
      if (
        typeof data === 'object' &&
        data !== null &&
        !(data instanceof File)
      ) {
        Object.entries(data).forEach(([key, value]) => {
          const formKey = rootName ? `${rootName}[${key}]` : key
          if (
            typeof value === 'object' &&
            value !== null &&
            !(value instanceof File)
          ) {
            appendFormData(value, formKey, formData) // 재귀적으로 객체 추가
          } else {
            formData.append(formKey, value)
          }
        })
      } else {
        // @ts-ignore
        formData.append(rootName, data)
      }
      return formData
    }

    // 객체 키들을 FormData에 추가
    Object.keys(videoInfo).forEach((key) => {
      if (key !== 'products') {
        // JSON 객체인 경우 재귀적으로 추가, 그렇지 않으면 그대로 추가
        // @ts-ignore
        if (typeof videoInfo[key] === 'object' && videoInfo[key] !== null) {
          // @ts-ignore
          appendFormData(videoInfo[key], key, formData)
        } else {
          // @ts-ignore
          formData.append(key, videoInfo[key])
        }
      }
    })

    // Append the product URLs and files
    videoInfo.products.forEach((product, productIndex) => {
      formData.append(`products[${productIndex}][link]`, product.link)
      formData.append(
        `products[${productIndex}][productName]`,
        product.productName
      )

      // Append the files for each product
      product.files.forEach((file: string | Blob, fileIndex: any) => {
        // 실제 파일 객체로 변환되어야 함
        if (file instanceof File) {
          formData.append(
            `products[${productIndex}][files][${fileIndex}]`,
            file
          )
        }
      })
    })

    mutate(formData, {
      onSuccess: () => {
        longFormCreateBtnClick()
        setRequest(true)
        setSaveSuccessModal((prev) => !prev)
        removeLocalStorage(LOCAL_THUMBNAIL_INFO)
        if (productsRef.current) {
          productsRef.current.focus()
          productsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      },
      onError: (e) => {
        // @ts-ignore
        const serverErrorMsg = e.response?.data?.message || e.message
        console.log(serverErrorMsg)
        setRequest(true)
        setEssentialCheck(true)
        setErrorText(serverErrorMsg)
        console.log(e.message)
      },
    })
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const [isOpen, setIsOpen] = useState(true)

  const toggleContent = () => {
    setIsOpen(!isOpen)
  }

  const [videoPlaying, setVideoPlaying] = useState<boolean[]>(
    new Array(transitionList?.length).fill(false)
  )
  const [voicePlaying, setVoicePlaying] = useState<boolean[]>(
    new Array(voiceList?.length).fill(false)
  )
  const [voicePrePlaying, setVoicePrePlaying] = useState<boolean[]>(
    new Array(voiceList?.length).fill(false)
  )

  const [bgmPlaying, setBgmPlaying] = useState<boolean[]>(
    new Array(bgmList?.length).fill(false)
  )

  const [thumbnailStatus, setThumbnailStatus] = useState<boolean[]>(
    new Array(thumbnailList?.length).fill(false)
  )

  const [toneTooltip, setToneTooltip] = useState<boolean[]>([])
  const [promptStatus, setPromptStatus] = useState<boolean[]>([])

  //여러개 tooltip 관리
  const tonRefs = useRef<(HTMLOrSVGElement | null)[]>([])
  const promptRefs = useRef<(HTMLOrSVGElement | null)[]>([])
  const thumbnailRefs = useRef<(HTMLDivElement | null)[]>([])

  const handleThumbnail = (index: number) => {
    const updatedThumbnailState = thumbnailStatus.map((value, idx) =>
      idx === index ? true : false
    )
    setThumbnailStatus(updatedThumbnailState)
    setThumbnailCheckModal(false)
  }

  const handlePromptMouseOver = (index: number) => {
    const updatedPromptState = promptStatus.map((value, idx) =>
      idx === index ? true : value
    )

    setPromptStatus(updatedPromptState)
  }

  const handlePromptMouseLeave = (index: number) => {
    const updatedPromptState = promptStatus.map((value, idx) =>
      idx === index ? false : value
    )
    setPromptStatus(updatedPromptState)
  }

  const handleMouseOver = (index: number) => {
    const updatedToneTooltipState = toneTooltip.map((value, idx) =>
      idx === index ? true : value
    )

    setToneTooltip(updatedToneTooltipState)
  }

  const handleMouseLeave = (index: number) => {
    const updatedToneTooltipState = toneTooltip.map((value, idx) =>
      idx === index ? false : value
    )
    setToneTooltip(updatedToneTooltipState)
  }

  // bgm / transition / voice handle function 부분
  const videoRefs = useRef<(HTMLVideoElement | null)[]>([])
  const voiceRefs = useRef<(HTMLAudioElement | null)[]>([])
  const voicePreRefs = useRef<(HTMLAudioElement | null)[]>([])
  const bgmRefs = useRef<(HTMLAudioElement | null)[]>([])

  const handleVoicePlayButtonClick = (status: boolean, index: number) => {
    const updatedPlayingState = voicePlaying.map((value, idx) =>
      idx === index ? status : false
    )
    setVoicePlaying(updatedPlayingState)
    if (voiceRefs.current[index]) {
      if (status) {
        voiceRefs.current[index]?.play()
      } else {
        voiceRefs.current[index]?.pause()
      }
    }
  }

  const handleVoicePrePlayButtonClick = (status: boolean, index: number) => {
    const updatedPlayingState = voicePrePlaying.map((value, idx) =>
      idx === index ? true : false
    )
    setVoicePrePlaying(updatedPlayingState)

    if (voicePreRefs.current[index]) {
      if (status) {
        voicePreRefs.current[index]?.play()
      } else {
        voicePreRefs.current[index]?.pause()
      }
    }
  }

  const handleBgmPlayButtonClick = (status: boolean, index: number) => {
    const updatedPlayingState = bgmPlaying.map((value, idx) =>
      idx === index ? status : false
    )
    setBgmPlaying(updatedPlayingState)

    if (bgmRefs.current[index]) {
      if (status) {
        bgmRefs.current[index]?.play()
      } else {
        bgmRefs.current[index]?.pause()
      }
    }
  }
  const handlePlayButtonClick = (index: number) => {
    const updatedPlayingState = videoPlaying.map((value, idx) =>
      idx === index ? true : false
    )
    setVideoPlaying(updatedPlayingState)

    if (videoRefs.current[index]) {
      videoRefs.current[index]?.play()
    }
  }

  const handleVideoEnded = (index: number) => {
    const updatedPlayingState = [...videoPlaying]
    updatedPlayingState[index] = false
    setVideoPlaying(updatedPlayingState)
  }

  const handleLoadedMetadata = (index: number) => {
    if (videoRefs.current[index]) {
      videoRefs.current[index]!.currentTime = 1
      videoRefs.current[index]!.pause()
    }
  }

  const setVideoData = () => {
    if (
      videoEditorData &&
      (userInfo?.userType === UserType.프리미엄 ||
        userInfo?.userType === UserType.프로)
    ) {
      const {
        voiceId,
        bgmId,
        transitionId,
        aiPromptTypeId,
        aiToneTypeId,
        subtitleId,
      } = videoEditorData
      if (aiToneTypeId) {
        setValue('aiToneTypeId', aiToneTypeId)
      }

      if (aiPromptTypeId) {
        setValue('aiPromptTypeId', aiPromptTypeId)
      }

      if (voiceId) {
        const [voiceInfo] = voiceList.filter((el) => el.id === voiceId)
        if (voiceInfo?.usable) {
          setValue('voiceId', voiceId)
        } else {
          setValue('voiceId', voiceList[0].id)
        }
      }
      if (transitionId) {
        setValue('transitionId', transitionId)
      }
      if (bgmId) {
        setValue('bgmId', bgmId)
      }
      if (subtitleId) {
        setValue('subtitleId', subtitleId)
      } else {
        if (subTitleList) {
          setValue('subtitleId', subTitleList[0].id)
        } else {
          setValue('subtitleId', 1)
        }
      }
    } else {
      if (toneList?.length) {
        setToneTooltip(new Array(toneList.length).fill(false))
        setValue('aiToneTypeId', toneList[0].id)
      }

      if (promptList?.length) {
        setPromptStatus(new Array(promptList.length).fill(false))
        setValue('aiPromptTypeId', promptList[0].id)
      }

      if (voiceList) {
        setValue('voiceId', voiceList[0].id)
      }
      if (transitionList) {
        setValue('transitionId', transitionList[0].id)
      }
      if (bgmList) {
        setValue('bgmId', bgmList[0].id)
      }
      if (subTitleList) {
        setValue('subtitleId', subTitleList[0].id)
      }
    }
  }
  useMemo(() => {
    if (voiceList) {
      setVoicePlaying(new Array(voiceList?.length).fill(false))
    }
    if (bgmList) {
      setBgmPlaying(new Array(bgmList?.length).fill(false))
    }
    if (toneList) {
      setToneTooltip(new Array(toneList.length).fill(false))
    }
    if (promptList) {
      setPromptStatus(new Array(promptList.length).fill(false))
    }

    setVideoData()
    if (userInfo) {
      if (
        userInfo?.userType === UserType.체험 ||
        userInfo?.userType === UserType.USER
      ) {
        setGuideModal(isTime ? false : true)
      }
      setUsage(userInfo.usage)
    }
  }, [userInfo, toneList, promptList, transitionList, bgmList, voiceList])

  useEffect(() => {
    voiceRefs.current.forEach((audioRef, index) => {
      if (audioRef && !voicePlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [voicePlaying])

  useEffect(() => {
    if (isTime === '1') {
      bgmRefetch()
      transitonRefetch()
      voiceRefetch()
      toneRefetch()
      promptRefetch()
      thumbnailRefetch()
    }
  }, [isTime])

  useEffect(() => {
    bgmRefs.current.forEach((audioRef, index) => {
      if (audioRef && !bgmPlaying[index]) {
        audioRef.pause()
        audioRef.currentTime = 0
      }
    })
  }, [bgmPlaying])

  useEffect(() => {
    if (thumbnailList) {
      setThumbnailStatus(new Array(thumbnailList.length).fill(false))
    }
  }, [thumbnailList])

  useEffect(() => {
    if (editThumbnail || isMobileTModal || isGuideModal) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [editThumbnail, isMobileTModal, isGuideModal])

  const [uploadImages, setUploadImages] = useState<File[]>([])
  const [selectedUploadImages, setSelectedUploadImages] = useState(
    new Array(uploadImages.length).fill(true)
  )

  const handleUploadCheckboxChange = (
    productIndex: number,
    fileIndex: number
  ) => {
    const currentFiles = watch(`products.${productIndex}.files`, [])

    const currentSelectedFiles = watch(
      `products.${productIndex}.selectedFiles`,
      []
    )

    const fileToToggle = currentFiles[fileIndex]
    const isSelected = currentSelectedFiles.includes(fileToToggle)

    if (isSelected) {
      // 이미 선택된 파일을 선택 해제
      setValue(
        `products.${productIndex}.selectedFiles`,
        currentSelectedFiles.filter((file) => file !== fileToToggle)
      )
    } else {
      // 선택된 파일이 3개 이상이면 추가 선택을 막습니다.
      if (currentSelectedFiles.length >= 3) return

      // 선택되지 않은 파일을 선택
      setValue(`products.${productIndex}.selectedFiles`, [
        ...currentSelectedFiles,
        fileToToggle,
      ])
    }
  }
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: any
  ) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files)
      // 업로드한 파일을 products[index].files에 추가
      // @ts-ignore
      setValue(`products.${index}.files`, [
        ...watch(`products.${index}.files`),
        ...selectedFiles,
      ])

      // 현재 선택된 파일 배열 가져오기
      const prevSelected = watch(`products.${index}.selectedFiles`, [])
      const additionalSelectionCount = selectedFiles.length
      const currentSelectionCount = prevSelected ? prevSelected.length : 0

      // 선택된 파일 상태 업데이트 (최대 3개만 선택 가능)
      if (currentSelectionCount + additionalSelectionCount > 3) {
        const allowedSelectionCount = 3 - currentSelectionCount
        // @ts-ignore
        setValue(`products.${index}.selectedFiles`, [
          ...prevSelected,
          ...selectedFiles.slice(0, allowedSelectionCount),
        ])
      } else {
        // @ts-ignore
        setValue(`products.${index}.selectedFiles`, [
          ...prevSelected,
          ...selectedFiles,
        ])
      }
    }
  }

  const getGridItemStyle = (selected: boolean): CSSProperties => ({
    width: '130px',
    height: '130px',
    position: 'relative',
    borderRadius: '4px',
    border: '1px solid #D9DEE8',
    overflow: 'hidden', // 이미지가 부모의 경계를 넘어가지 않도록 설정
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FFFFFF',
    opacity: selected ? 1 : 0.3, // 선택되지 않은 경우 투명도 조절
  })

  // @ts-ignore
  const styles: { [key: string]: CSSProperties } = {
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(5, 1fr)',
    },
    gridContainerMobile: {
      gridTemplateColumns: 'repeat(3, 1fr)', // 각 아이템의 너비를 유지하면서 자동으로 간격 조정
      justifyItems: 'center',
    },
    gridItemMobile: {
      width: '93px',
      height: '93px',
    },
    image: {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'contain', // 비율을 유지하며 부모를 꽉 채우도록 설정
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkbox: {
      marginRight: '8px',
      width: '16px',
      height: '16px',
    },
    label: {
      fontSize: '16px',
      color: '#333',
    },
  }

  // @ts-ignore
  return (
    <>
      <div className="pt-[45px] md:max-w-[816px] max-w-[375px] my-0 mx-auto flex flex-col items-center gap-[16px]">
        <div className="flex flex-col items-center w-[343px] md:w-full">
          <h1 className="font-bold text-[28px] md:text-[48px]">클릭 한 번에</h1>
          <div className="flex items-center gap-1 mb-2">
            <h1 className="font-bold text-[28px] md:text-[48px]">
              상품 소개 영상
            </h1>
            <img
              src="/assets/img/icon_youtube.png"
              alt="youtube icon"
              className="md:w-[53px] md:h-[53px] w-[30px] h-[30px]"
            />
            <h1 className="font-bold text-[28px] md:text-[48px]">
              유튜브 등록!
            </h1>
          </div>
          <div className="">
            <p className="flex justify-center items-center gap-[4px]">
              <img
                src="assets/img/coupang_icon.png"
                alt="coupang"
                width={17.76}
                height={17.42}
              />
              <img
                src="assets/img/ali_icon.png"
                alt="ali"
                width={17.76}
                height={17.42}
              />
              어필리에이트 링크만 있으면,
            </p>
            {isMobile ? (
              <>
                <div className="flex flex-col items-center">
                  <p className="">
                    <span className='text-main font-bold"'>FASTCUT AI </span> 가
                    나레이션, 편집, BGM 넣은
                  </p>
                  <p>영상을 내 채널에 등록까지 해줘요</p>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-center">
                  <p className="text-main font-bold">FASTCUT AI</p>
                  <p>
                    가 나레이션, 편집, BGM 넣은 영상을 내 채널에 등록까지 해줘요
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col w-full gap-[8px] ">
          {!userInfo && <WelcomeBox />}

          {/* 사용을 다하고 체험일 경우에만 이 error 박스가 나오지 않았으면 합니다.  */}
          {!usage?.available && userInfo?.userType === UserType.체험 ? (
            <> </>
          ) : (
            showErrorBox(userInfo)
          )}

          {/* 횟수 제한 표시 dic */}
          {usage &&
          !usage?.available &&
          userInfo?.userType !== UserType.체험 ? (
            <div className="bg-[#F6F8FB] w-full h-[86px] rounded-[40px] flex md:flex-row flex-col items-center px-[40px] py-[32px] ">
              <p className="font-bold">
                기한 내 사용 가능 횟수를 모두 소진하셨어요!
              </p>
              <p className="font-bold ml-[18px]">
                <span className="text-main">요청 {usage.usedCount}개</span> /{' '}
                {usage?.totalLimit}개
              </p>
            </div>
          ) : usage &&
            usage?.available &&
            userInfo?.userType === UserType.체험 ? (
            <>
              <BeforeGuideCircle />
            </>
          ) : (
            usage &&
            !usage?.available &&
            userInfo?.userType === UserType.체험 && (
              <>
                <AfterGuideCircle />
              </>
            )
          )}
          <PromotionCircle
            userInfo={userInfo}
            planId={userInfo?.subscriptionType?.planId}
          />
        </div>

        <form
          onSubmit={handleSubmit(onSubmit, (err) => {
            if (!userInfo) {
              return navigate('/sign-up')
            }
            const { mainTitle, products } = err

            console.log(err)

            const selectErrorText = (mainTitle: any, products: any) => {
              let mainTitleErrorText =
                '영상 제목(90자 제한), 썸네일 내용 입력을 하셔야 영상 생성 요청이 가능합니다.'
              let productsErrorText = '최소 1개의 상품 링크와 상품명'
              let errorStr = ''
              if (mainTitle && products) {
                errorStr = `${productsErrorText}, ${mainTitleErrorText}`

                if (mainTitleInputRef.current) {
                  mainTitleInputRef.current.focus()
                  mainTitleInputRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              } else if (!mainTitle && products) {
                errorStr = `${productsErrorText}을 입력해주셔야 영상 생성 요청이 가능합니다`

                for (const product of products) {
                  if (product) {
                    const { link, productName } = product
                    const linkIndex = link?.ref?.name.replace(
                      /products|\blink\b|\.|/g,
                      ''
                    )
                    const nameIndex = productName?.ref?.name.replace(
                      /products|\bproductName\b|\.|/g,
                      ''
                    )
                  }
                }
                if (productsRef.current) {
                  productsRef.current.focus()
                  productsRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              } else if (mainTitle && !products) {
                const { type } = mainTitle

                errorStr = mainTitle?.message
                if (mainTitleInputRef.current) {
                  mainTitleInputRef.current.focus()
                  mainTitleInputRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
              }

              setErrorText(errorStr)
            }
            selectErrorText(mainTitle, products)
            setEssentialCheck(true)
          })}
          className={`flex flex-col gap-[20px] mt-[20px] ${
            usage &&
            userInfo?.userType !== UserType.체험 &&
            !usage?.available &&
            'opacity-[0.2]'
          }`}
        >
          <ShadowBox className=" md:w-[816px]">
            <div className="flex flex-col items-center gap-[4px] md:gap-[16px] w-[374px] md:w-[736px]">
              <div className="flex flex-col items-center mt-[40px] md:mt-0 w-[343px] md:w-full">
                <h3 className="font-bold text-[#2E343D] text-[24px] ">
                  상품 링크 입력
                </h3>
                <p className="text-main text-[14px]">
                  1~3개의 상품 정보와 리뷰로 영상을 만들어보세요!
                </p>

                <p className="text-[#464DEE] text-[14px] text-center">
                  🧐 대표 영상 소스가 있는 알리익스프레스 상품 영상 퀄리티가 더
                  좋습니다!
                </p>

                <p className="text-[14px] text-center text-error">
                  🚨 상품페이지 링크만 가능해요 (목록 페이지는 안돼요!)
                </p>
              </div>

              <div className="flex flex-col gap-[16px]">
                <div className="flex flex-col items-center w-full md:w-[736px] gap-[8px] ">
                  {fields.map((item, index) => (
                    <>
                      <div
                        key={index}
                        className="w-[320px] md:w-full flex justify-between"
                      >
                        <p className="text-[18px] font-bold">
                          상품 {index + 1}
                        </p>
                      </div>
                      <div className="relative">
                        <Controller
                          key={`link_${index}`}
                          render={({ field }) => (
                            <Input
                              inputRef={index === 0 ? productsRef : null}
                              disabled={
                                usage &&
                                !usage?.available &&
                                userInfo?.userType !== UserType.체험
                              }
                              {...field}
                              placeholder="쿠팡파트너스 또는 알리어필리에이트 링크 Ctrl+c, Ctrl+v 로 붙여넣기"
                              style={{
                                borderColor:
                                  errors.products &&
                                  errors.products[index] &&
                                  errors.products[index]?.link?.message ===
                                    'invalid' &&
                                  'red',
                              }}
                              userCheck={userCheck}
                            />
                          )}
                          name={`products.${index}.link`}
                          control={control}
                          rules={{
                            required:
                              (index === 0 ||
                                watch(`products.${index}.productName`)) !== ''
                                ? 'required'
                                : false,
                            validate:
                              index === 0 ||
                              watch(`products.${index}.productName`) !== ''
                                ? (value) => {
                                    return URL_PATTERN.test(value) || `invalid`
                                  }
                                : undefined,
                          }}
                        />
                        {errors.products &&
                          errors.products[index]?.link &&
                          errors.products[index]?.link?.message ===
                            'invalid' && (
                            <span className="text-error">
                              유효하지 않은 링크입니다!
                            </span>
                          )}
                        {index === 0 && (
                          <>
                            <img
                              src="assets/img/icon_tooltip.png"
                              alt="툴팁"
                              className="absolute right-5 top-[24px]  w-[24px] h-[24px]"
                              onMouseOver={() => {
                                setTooltipStatus(true)
                              }}
                            />
                            {tooltipStatus && (
                              <div
                                className="flex flex-col p-[16px] md:p-[32px] gap-[10px] bg-white w-[200px] md:w-[360px] h-auto absolute border-[3px] border-main  rounded-[40px] bottom-[50px] right-0"
                                onMouseLeave={() => {
                                  setTooltipStatus(false)
                                }}
                              >
                                <p className=" md:leading-[19.2px] md:text-base  leading-[12px] text-[10px]">
                                  <span className="font-bold">
                                    쿠팡파트너스? 알리어필리에이트?
                                  </span>
                                  <br />위 서비스들의 계정을 생성하셔야,
                                  제휴마케팅 리워드를 받을 수 있는 상품 링크를
                                  복사할 수 있어요!
                                  <br />
                                  아직 만들지 않으셨다면,
                                  <br />
                                  패스트컷의 가이드를 보고 쉽게 만들어보세요!
                                </p>
                                <p className="flex gap-2 md:text-base text-[10px]">
                                  <img
                                    src="assets/img/coupang_icon.png"
                                    alt="coupang"
                                    width={24}
                                    height={24}
                                    className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
                                  />
                                  <a
                                    href="https://solution.fastseller.shop/15cc8c5d-bcd4-4d50-b145-4f32bad40634"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="md:text-base text-[10px]"
                                  >
                                    {' '}
                                    {`쿠팡 파트너스 가이드 >`}
                                  </a>
                                </p>
                                <p className="flex gap-2 md:text-base text-[10px]">
                                  <img
                                    src="assets/img/ali_icon.png"
                                    alt="ali"
                                    className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
                                  />
                                  <a
                                    href="https://solution.fastseller.shop/2ad66cae-7290-42eb-a3f4-83fcf3fc15c8"
                                    target="_blank"
                                    rel="noreferrer"
                                  >{`알리익스프레스 어필리에이트 가이드 >`}</a>
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="mb-[16px] product_title">
                        <Controller
                          key={`productName_${index}`}
                          render={({ field }) => (
                            <>
                              <Input
                                error={
                                  field.value.length > 25 ||
                                  !PRODUCT_NAME_PATTERN.test(field.value)
                                    ? true
                                    : false
                                }
                                disabled={
                                  usage &&
                                  !usage?.available &&
                                  userInfo?.userType !== UserType.체험
                                }
                                {...field}
                                placeholder="영상에 표기될 상품명을 깔끔하게 정리해서 적어주세요! (25자 이하)"
                                maxLength={100}
                                userCheck={userCheck}
                              />

                              <div
                                className={`flex gap-[4px] mt-[8px] ${
                                  (field.value.length > 25 ||
                                    !PRODUCT_NAME_PATTERN.test(field.value)) &&
                                  'text-error'
                                }`}
                              >
                                <span>{field.value.length}/25</span>
                                <span>{`이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.`}</span>
                                {errors.products &&
                                  errors.products[index]?.productName && (
                                    <span className="text-error ml-[10px]">
                                      {errors.products[index]?.productName
                                        ?.type === 'required' &&
                                        '상품명을 입력해주세요.'}
                                    </span>
                                  )}
                              </div>
                            </>
                          )}
                          name={`products.${index}.productName`}
                          control={control}
                          rules={{
                            required:
                              (index === 0 ||
                                watch(`products.${index}.link`)) !== ''
                                ? 'required'
                                : false,
                            validate:
                              index === 0 ||
                              watch(`products.${index}.productName`) !== ''
                                ? (value) => {
                                    return (
                                      PRODUCT_NAME_PATTERN.test(value) ||
                                      '이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.'
                                    )
                                  }
                                : undefined,
                            maxLength: 25,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: '100%',
                          background: '#F6F8FB',
                          borderRadius: '10px',
                          padding: '20px',
                          maxHeight: 'calc(2 * 230px)', // 2줄까지만 보이도록 높이 제한 (2줄 높이 + 갭)
                          overflowY: 'auto', // 스크롤 가능하게 설정
                          scrollbarWidth: 'thin', // Firefox용 기본 스크롤바 폭 설정
                          scrollbarColor: '#888 #e0e0e0', // Firefox용 스크롤바 색상 설정
                        }}
                      >
                        {/* 이미지 업로드 UI 추가 */}
                        <div className="text-[14px] mt-5 mb-1">
                          <span className="font-bold">
                            이미지 업로드{' '}
                            {
                              watch(`products.${index}.files`, []).filter(
                                (image) => image
                              ).length
                            }
                            /3
                          </span>{' '}
                          (최대 3장 선택 가능)
                        </div>
                        {index === 0 && (
                          <p className="text-[14px] text-error mb-1">
                            상품 페이지 영상이 없는 경우에만 적용되며, 롱폼
                            생성시 업로드 이미지를 앞쪽에 배치합니다.
                          </p>
                        )}
                        <div className="flex items-center space-x-2 mb-3">
                          <span className="text-[#8F929B] text-[16px] text-sm">
                            최대 5MB | jpg, jpeg, png
                          </span>
                          <button
                            onClick={(event) => {
                              event.preventDefault() // 기본 form 제출 동작을 방지
                              document
                                .getElementById(`file-input-${index}`)
                                ?.click() // 파일 선택 창 열기
                            }}
                            className="flex items-center space-x-1 px-3 py-2 text-white rounded-full bg-[#6B8ACB] text-[14px] font-bold"
                          >
                            <img src="assets/img/icon_image.png" width="17px" />
                            <span>파일 업로드</span>
                          </button>
                          <input
                            id={`file-input-${index}`}
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            multiple
                            className="hidden"
                            onChange={(e) => handleFileChange(e, index)}
                          />
                        </div>

                        <div
                          style={{
                            ...styles.gridContainer,
                            ...(window.innerWidth <= 768
                              ? styles.gridContainerMobile
                              : {}),
                          }}
                        >
                          {watch(`products.${index}.files`, []).map(
                            (file, fileIndex) => {
                              const selectedFiles = watch(
                                `products.${index}.selectedFiles`,
                                []
                              )
                              console.log('selectedFiles:::::', selectedFiles)
                              return (
                                <div
                                  style={{ marginBottom: '20px' }}
                                  key={fileIndex}
                                >
                                  <div
                                    style={{
                                      ...getGridItemStyle(
                                        watch(
                                          `products.${index}.selectedFiles`,
                                          []
                                        ).includes(file)
                                      ),
                                      ...(window.innerWidth <= 768
                                        ? styles.gridItemMobile
                                        : {}),
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`Image ${fileIndex + 1}`}
                                      style={styles.image}
                                    />
                                  </div>
                                  <div style={styles.checkboxContainer}>
                                    <input
                                      type="checkbox"
                                      checked={watch(
                                        `products.${index}.selectedFiles`,
                                        []
                                      ).includes(file)}
                                      onChange={() =>
                                        handleUploadCheckboxChange(
                                          index,
                                          fileIndex
                                        )
                                      }
                                      style={styles.checkbox}
                                    />
                                    <span style={styles.label}>
                                      {String(fileIndex + 1).padStart(2, '0')}
                                    </span>
                                  </div>
                                </div>
                              )
                            }
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                <div className="w-full flex justify-center">
                  {fields.length < 3 && (
                    <button
                      type="button"
                      onClick={() => {
                        if (fields.length < 3) {
                          append({
                            link: '',
                            productName: '',
                            files: [],
                            selectedFiles: [],
                          })
                        }
                      }}
                    >
                      <img
                        src="assets/img/add.png"
                        height={32}
                        width={32}
                        alt="add"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </ShadowBox>
          {isSample === '1' && (
            <div className=" sample md:w-[816px] my-[40px] flex flex-col gap-[24px] bg-[#F6F8FB] p-[20px] md:p-[40px] md:rounded-[40px]">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <p className="h-[15px]"></p>
                  <p className="text-[#2E343D] font-bold leading-[28.8px] text-left text-[24px] h-[120px] md:h-[60px]">
                    쿠팡파트너스 / 알리어필리에이트 링크가 아직 없으신가요?
                    <br /> 다음 링크를 사용해서 패스트컷을 경험해보세요!
                  </p>
                </div>

                <div className="flex flex-col md:flex-row">
                  <p className="flex w-[40px] h-[40px] justify-end">
                    <button
                      type="button"
                      onClick={() => {
                        saveLocalStorage('guide_sample', '0')
                        setSample('0')
                      }}
                    >
                      <img
                        src="assets/img/Icon_x.png"
                        alt="icon_x"
                        height={40}
                        width={40}
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row gap-[4px]">
                {products?.map((el) => {
                  return (
                    <>
                      <div className="flex gap-[4px] w-full md:w-[50%]">
                        <div className="w-[120px] h-[120px]">
                          <img
                            src={el.thumbnail}
                            alt="sample"
                            className="border rounded-[10px] w-[120px] h-[120px]"
                            loading={'lazy'}
                          />
                        </div>
                        <div className="w-[211px] md:w-[218.5px] flex flex-col justify-between break-words gap-[6px]">
                          <a
                            href={el.url}
                            target="_blank"
                            className="font-bold text-blue-500 underline"
                            rel="noreferrer"
                          >
                            {el.url}
                          </a>
                          <p className="font-bold leading-[20.88px]">
                            {el.title}
                          </p>
                          <p>{el.price}</p>
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          )}

          <ShadowBox className="py-[40px]  md:w-[816px]">
            <div
              className="flex flex-col gap-[16px]  w-full "
              onClick={userCheck}
            >
              <div className="flex flex-col items-center ">
                <h3 className="font-bold text-[#2E343D] text-[24px] ">
                  유튜브 영상용 제목 입력
                </h3>
                <p className="text-main text-[14px]">
                  매력적인 내용을 정해주세요!
                </p>
              </div>
              <div className="flex flex-col items-center w-full md:w-[736px] gap-[8px]">
                <div className="relative">
                  <Controller
                    render={({ field }) => (
                      <>
                        <Input
                          inputRef={mainTitleInputRef}
                          error={
                            field.value.length > 90 ||
                            !PRODUCT_NAME_PATTERN.test(field.value)
                              ? true
                              : false
                          }
                          disabled={
                            usage &&
                            !usage?.available &&
                            userInfo?.userType !== UserType.체험
                          }
                          {...field}
                          placeholder="유튜브 영상용 제목 입력 (90자 이하)"
                          maxLength={90}
                        />
                        <div
                          className={`flex gap-[4px] mt-[8px] ${
                            (field.value.length > 90 ||
                              !PRODUCT_NAME_PATTERN.test(field.value)) &&
                            'text-error'
                          }`}
                        >
                          <span>{field.value.length}/90</span>
                          <span>{`이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.`}</span>
                        </div>
                      </>
                    )}
                    name={'mainTitle'}
                    rules={{
                      required: {
                        value: true,
                        message: '유튜브 영상 제목은 필수입니다!',
                      },
                      maxLength: {
                        value: 90,
                        message: '유튜브 영상 제목의 길이가 90자를 넘습니다.',
                      },
                      validate:
                        watch(`mainTitle`) !== ''
                          ? (value) => {
                              return (
                                PRODUCT_NAME_PATTERN.test(value) ||
                                '이모지와 꺽쇠괄호(< >)는 허용되지 않습니다.'
                              )
                            }
                          : undefined,
                    }}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </ShadowBox>

          <ShadowBox className="py-[40px] px-[16px] md:w-[816px] ">
            <div className="flex flex-col gap-[40px] md:gap-0 w-[343px] md:w-[736px]">
              <div className="flex flex-col items-center  mt-[16px] md:mb-[40px] ">
                <p className="font-bold text-[#2E343D] text-[24px] ">
                  효과 선택
                </p>
                <p className="text-[#FFFFFF] bg-main text-[10px] font-bold w-[159px] text-center rounded-[4px] h-[18px] flex items-center justify-center">
                  <span>프로 / 프리미엄 플랜을 위한 기능</span>
                </p>
                <p className="text-main text-[14px]">
                  원하는 분위기의 영상 생성을 위해 각 효과를 선택해주세요!
                </p>
              </div>
              <CSSTransition
                in={isOpen}
                timeout={200}
                classNames="content"
                unmountOnExit
              >
                <div className="">
                  {isMobile ? (
                    <>
                      <div className="thumbnail_mobile flex flex-col items-center mb-[40px]">
                        <p className="font-bold text-[18px] mb-[16px]">
                          썸네일 내용 입력 + 썸네일 스타일 (프리미엄 플랜 전용)
                        </p>
                        <div className="flex justify-center w-[313px]">
                          <div className="grid grid-cols-2 gap-x-[37px] gap-y-[40px]">
                            {thumbnailList?.map((thumbnail, index) => {
                              return (
                                <>
                                  <div
                                    className={`flex gap-[8px] flex-col relative `}
                                    key={index}
                                    ref={(el) =>
                                      (thumbnailRefs.current[index] = el)
                                    }
                                  >
                                    <img
                                      src={thumbnail.path}
                                      width={138}
                                      height={78}
                                      alt="img"
                                      className={` relative ${
                                        thumbnailStatus[index]
                                          ? 'outline outline-4 outline-main'
                                          : ''
                                      }`}
                                    />
                                    {thumbnailStatus[index] && (
                                      <button
                                        className=" absolute left-7 top-7 md:bottom-[50%] md:left-[20%] bg-main z-50 w-[79px] h-[24px] rounded-[20px] flex justify-center items-center"
                                        type="button"
                                        onClick={() => {
                                          setThumbnailType(
                                            thumbnailOptions[
                                              `thumbnail_${thumbnail.name}`
                                            ]
                                          )
                                          setMobileTModal(true)
                                        }}
                                      >
                                        <span className="font-bold text-[#FFFFFF] text-[12px]">
                                          편집하기
                                        </span>
                                      </button>
                                    )}
                                    <div className="flex gap-[8px] items-center">
                                      <Controller
                                        render={({ field }) => (
                                          <CircularCheckbox
                                            {...field}
                                            id={index}
                                            checked={
                                              watch('thumbnailId') ===
                                              thumbnail.id
                                            }
                                            disabled={
                                              !userInfo || !thumbnail?.usable
                                            }
                                            onChange={() => {
                                              const tlocal =
                                                getLocalStorage(
                                                  LOCAL_THUMBNAIL_INFO
                                                )
                                              if (
                                                watch('thumbnailId') === 0 ||
                                                watch('thumbnailId') ===
                                                  thumbnail.id ||
                                                !tlocal
                                              ) {
                                                // 모달이 뜨지 않아도 되는 경우
                                                setValue(
                                                  'thumbnailId',
                                                  thumbnail.id
                                                )
                                                handleThumbnail(index)
                                              } else {
                                                setPrevThumbnail({
                                                  id: thumbnail.id,
                                                  index,
                                                })
                                                setThumbnailCheckModal(
                                                  (prev) => !prev
                                                )
                                              }
                                            }}
                                            label={
                                              index === 0
                                                ? `${thumbnail.name} (라이트)`
                                                : thumbnail.name
                                            }
                                          />
                                        )}
                                        name="thumbnailId"
                                        control={control}
                                      />
                                    </div>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="thumbnail_pc  mb-[40px]">
                      <p className="font-bold text-[18px] mb-[16px]">
                        썸네일 내용 입력 + 썸네일 스타일 (프리미엄 플랜 전용)
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-5  gap-y-[16px]">
                        {thumbnailList?.map((thumbnail, index) => {
                          return (
                            <>
                              <div
                                className={`flex gap-[24px] md:flex-col md:gap-2 relative `}
                                key={index}
                                ref={(el) =>
                                  (thumbnailRefs.current[index] = el)
                                }
                              >
                                <img
                                  src={thumbnail.path}
                                  width={138}
                                  height={78}
                                  alt="img"
                                  className={` relative ${
                                    thumbnailStatus[index]
                                      ? 'outline outline-4 outline-main'
                                      : ''
                                  }`}
                                />
                                {thumbnailStatus[index] && (
                                  <button
                                    className=" absolute left-7 top-7 md:bottom-[50%] md:left-[20%] bg-main z-50 w-[79px] h-[24px] rounded-[20px] flex justify-center items-center"
                                    type="button"
                                    onClick={() => {
                                      setThumbnailType(
                                        thumbnailOptions[
                                          `thumbnail_${thumbnail.name}`
                                        ]
                                      )
                                      setEditThumbnail(true)
                                    }}
                                  >
                                    <span className="font-bold text-[#FFFFFF] text-[12px]">
                                      편집하기
                                    </span>
                                  </button>
                                )}
                                <div className=" relative flex gap-[8px] items-center">
                                  <Controller
                                    render={({ field }) => (
                                      <CircularCheckbox
                                        {...field}
                                        id={index}
                                        checked={
                                          watch('thumbnailId') === thumbnail.id
                                        }
                                        disabled={
                                          !userInfo || !thumbnail?.usable
                                        }
                                        onChange={() => {
                                          const tlocal =
                                            getLocalStorage(
                                              LOCAL_THUMBNAIL_INFO
                                            )
                                          if (
                                            watch('thumbnailId') === 0 ||
                                            watch('thumbnailId') ===
                                              thumbnail.id ||
                                            !tlocal
                                          ) {
                                            // 모달이 뜨지 않아도 되는 경우
                                            setValue(
                                              'thumbnailId',
                                              thumbnail.id
                                            )
                                            handleThumbnail(index)
                                          } else {
                                            setPrevThumbnail({
                                              id: thumbnail.id,
                                              index,
                                            })
                                            setThumbnailCheckModal(
                                              (prev) => !prev
                                            )
                                          }
                                        }}
                                        label={thumbnail.name}
                                      />
                                    )}
                                    name="thumbnailId"
                                    control={control}
                                  />

                                  <PlanTag
                                    planName={translatePlanName(
                                      thumbnail.planName
                                    )}
                                    className="absolute left-[35%] top-0"
                                  />
                                </div>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    </div>
                  )}
                  <div className="subtitle mb-[40px]">
                    <p className="text-[18px] mb-[16px] flex items-center gap-1 relative">
                      <span className="font-bold">나레이션 자막</span>
                      {/*<SubtitleToolltip*/}
                      {/*  setSubtitleTooltip={setSubtitleTooltip}*/}
                      {/*  subtitleTooltip={subtitleTooltip}*/}
                      {/*/>*/}
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[53.67px] gap-y-[16px]">
                      {subTitleList?.map((subtitle, index) => {
                        return (
                          <>
                            <div
                              className="w-full flex justify-between md:items-center"
                              key={index}
                            >
                              <div className="flex ">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={
                                        watch('subtitleId') === subtitle.id
                                      }
                                      disabled={
                                        !userInfo ||
                                        userInfo?.userType === 'normalUser' ||
                                        userInfo?.userType === 'lightUser'
                                      }
                                      onChange={() =>
                                        setValue('subtitleId', subtitle.id)
                                      }
                                      label={subtitle.name}
                                    />
                                  )}
                                  name="subtitleId"
                                  control={control}
                                />
                                <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                                  <PlanTag
                                    planName={translatePlanName(
                                      subtitle.planName
                                    )}
                                    className=""
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>
                  <div className="ai-prompt  mb-[40px]">
                    <p className="font-bold text-[18px] mb-[16px]">
                      AI 생성 대본 컨셉
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[53.67px] gap-y-[16px]">
                      {promptList?.map((prompt, index) => {
                        return (
                          <>
                            <div
                              className="w-full flex justify-between md:items-center"
                              key={index}
                            >
                              <div className="flex h-full items-center">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={
                                        watch('aiPromptTypeId') === prompt.id
                                      }
                                      disabled={
                                        !userInfo ||
                                        userInfo?.userType === 'normalUser' ||
                                        userInfo?.userType === 'lightUser'
                                      }
                                      onChange={() =>
                                        setValue('aiPromptTypeId', prompt.id)
                                      }
                                      label={prompt.name}
                                    />
                                  )}
                                  name="aiPromptTypeId"
                                  control={control}
                                />
                                <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                                  <PlanTag
                                    planName={translatePlanName(
                                      prompt.planName
                                    )}
                                    className=""
                                  />
                                </div>
                                <TooltipIcon
                                  tone={prompt}
                                  index={index}
                                  tonRefs={promptRefs}
                                  toneTooltip={promptStatus}
                                  handleMouseOver={handlePromptMouseOver}
                                  handleMouseLeave={handlePromptMouseLeave}
                                />
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="ai-tone  mb-[40px]">
                    <p className="font-bold text-[18px] mb-[16px]">
                      AI 생성 나레이션 말투
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-[53.67px] gap-y-[16px]">
                      {toneList?.map((tone, index) => {
                        return (
                          <>
                            <div
                              className="w-full flex justify-between md:items-center"
                              key={index}
                            >
                              <div className="flex items-center h-full">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={
                                        watch('aiToneTypeId') === tone.id
                                      }
                                      disabled={
                                        !userInfo ||
                                        userInfo?.userType === 'normalUser' ||
                                        userInfo?.userType === 'lightUser'
                                      }
                                      onChange={() =>
                                        setValue('aiToneTypeId', tone.id)
                                      }
                                      label={tone.name}
                                    />
                                  )}
                                  name="aiToneTypeId"
                                  control={control}
                                />
                                <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                                  <PlanTag
                                    planName={translatePlanName(tone.planName)}
                                    className=""
                                  />
                                </div>
                                <TooltipIcon
                                  tone={tone}
                                  index={index}
                                  tonRefs={tonRefs}
                                  toneTooltip={toneTooltip}
                                  handleMouseOver={handleMouseOver}
                                  handleMouseLeave={handleMouseLeave}
                                />
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="tts  md:mb-[40px]">
                    <p className="font-bold text-[18px] mb-[16px]">목소리</p>

                    <div className="flex justify-between w-[156.5px] md:w-[193px] mb-[16px] gap-[8px]">
                      <div className="flex">
                        <Controller
                          render={({ field }) => (
                            <CircularCheckbox
                              {...field}
                              id={0}
                              checked={watch('voiceId') === voiceList[0]?.id}
                              disabled={!voiceList[0]?.usable}
                              onChange={() =>
                                setValue(
                                  'voiceId',
                                  voiceList[0]?.id ? voiceList[0]?.id : 0
                                )
                              }
                              label={`${voiceList[0]?.name}`}
                            />
                          )}
                          name="voiceId"
                          control={control}
                        />
                        <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                          <PlanTag
                            planName={translatePlanName(voiceList[0]?.planName)}
                            className=""
                          />
                        </div>
                      </div>
                      <div className="flex gap-[12px]">
                        <button
                          type="button"
                          onClick={() =>
                            handleVoicePlayButtonClick(!voicePlaying[0], 0)
                          }
                        >
                          <audio
                            ref={(ref) => {
                              voiceRefs.current[0] = ref
                              if (ref) {
                                ref.onended = () => {
                                  const updatedPlayingState = [...voicePlaying]
                                  updatedPlayingState[0] = false
                                  setVoicePlaying(updatedPlayingState)
                                }
                              }
                            }}
                          >
                            <source
                              src={
                                voiceList[0]?.path
                                  ? voiceList[0]?.path
                                  : 'https://pub.angelleague.io/fast_cut/voice/output_gtts1.5.mp3'
                              }
                              type="audio/mpeg"
                            />
                          </audio>
                          <img
                            src={
                              !voicePlaying[0]
                                ? 'assets/img/icon_play.png'
                                : 'assets/img/icon_pause.png'
                            }
                            alt="play"
                            className="w-[29.25px] h-[29.25px]"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-x-[30px] md:gap-x-[53.67px] gap-y-[16px] mb-[40px]">
                      {voiceList?.map((voice, index) => {
                        if (index === 0) return null
                        return (
                          <>
                            <div
                              className="md:w-[193px] w-full flex gap-[8px] justify-between md:items-center"
                              key={index}
                            >
                              <div className="flex">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={watch('voiceId') === voice.id}
                                      disabled={!voice?.usable}
                                      onChange={() =>
                                        setValue('voiceId', voice.id)
                                      }
                                      label={voice.name}
                                    />
                                  )}
                                  name="voiceId"
                                  control={control}
                                />
                                <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                                  <PlanTag
                                    planName={translatePlanName(
                                      voice?.planName
                                    )}
                                    className=""
                                  />
                                </div>
                              </div>

                              <div className="flex gap-[12px]">
                                <button
                                  type="button"
                                  onClick={() =>
                                    handleVoicePlayButtonClick(
                                      !voicePlaying[index],
                                      index
                                    )
                                  }
                                >
                                  <audio
                                    ref={(ref) => {
                                      voiceRefs.current[index] = ref
                                      if (ref) {
                                        ref.onended = () => {
                                          const updatedPlayingState = [
                                            ...voicePlaying,
                                          ]
                                          updatedPlayingState[index] = false
                                          setVoicePlaying(updatedPlayingState)
                                        }
                                      }
                                    }}
                                  >
                                    <source
                                      src={voice.path}
                                      type="audio/mpeg"
                                    />
                                  </audio>
                                  <img
                                    src={
                                      !voicePlaying[index]
                                        ? 'assets/img/icon_play.png'
                                        : 'assets/img/icon_pause.png'
                                    }
                                    alt="play"
                                    className="w-[29.25px] h-[29.25px]"
                                  />
                                </button>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="bgm  md:mb-[40px]">
                    <p className="font-bold text-[18px] mb-[16px]">배경음악</p>
                    <div className="grid grid-cols-2 md:grid-cols-3  gap-x-[22px] gap-y-[16px]">
                      {bgmList?.map((bgm, index) => {
                        return (
                          <>
                            <div
                              className="flex items-center  justify-between space-x-2  w-full md:w-[228px]"
                              key={index}
                            >
                              <div className="flex">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={watch('bgmId') === bgm.id}
                                      onChange={() => setValue('bgmId', bgm.id)}
                                      disabled={
                                        !userInfo ||
                                        userInfo?.userType === 'normalUser' ||
                                        userInfo?.userType === 'lightUser'
                                      }
                                      label={bgm.name}
                                    />
                                  )}
                                  name="bgmId"
                                  control={control}
                                />
                                <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                                  <PlanTag
                                    planName={translatePlanName(bgm?.planName)}
                                    className=""
                                  />
                                </div>
                              </div>
                              <div className="flex gap-[12px]">
                                <audio
                                  ref={(ref) => {
                                    bgmRefs.current[index] = ref
                                    if (ref) {
                                      ref.onended = () => {
                                        const updatedBgmPlayingState = [
                                          ...bgmPlaying,
                                        ]
                                        updatedBgmPlayingState[index] = false
                                        setBgmPlaying(updatedBgmPlayingState)
                                      }
                                    }
                                  }}
                                >
                                  <source src={bgm.path} type="audio/mpeg" />
                                </audio>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleBgmPlayButtonClick(
                                      !bgmPlaying[index],
                                      index
                                    )
                                  }}
                                >
                                  <img
                                    src={
                                      !bgmPlaying[index]
                                        ? 'assets/img/icon_play.png'
                                        : 'assets/img/icon_pause.png'
                                    }
                                    alt="play"
                                    className="w-[29.25px] h-[29.25px]"
                                  />
                                </button>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>

                  <div className="transition  my-[40px]">
                    <p className="font-bold text-[18px] mb-[16px]">
                      화면 전환 효과
                    </p>
                    <div className="flex md:flex-row flex-col gap-[24px] md:gap-1 justify-between">
                      {transitionList?.map((transition, index) => {
                        return (
                          <>
                            <div
                              className="flex gap-[24px] md:flex-col md:gap-2 relative"
                              key={index}
                            >
                              <video
                                ref={(ref) => (videoRefs.current[index] = ref)}
                                src={transition.path}
                                width={138}
                                height={78}
                                onEnded={() => handleVideoEnded(index)}
                                onLoadedMetadata={() =>
                                  handleLoadedMetadata(index)
                                }
                              />
                              <div
                                className={`absolute w-[138px] h-[78px] ${
                                  videoPlaying[index] ? 'hidden' : ''
                                } bg-black opacity-[0.5] `}
                              >
                                <button
                                  type="button"
                                  onClick={() => handlePlayButtonClick(index)}
                                  className="absolute top-[30%] left-[40%]"
                                >
                                  <img
                                    src="assets/img/icon_white_play.png"
                                    alt="play"
                                    width={29.25}
                                    height={29.25}
                                  />
                                </button>
                              </div>
                              <div className="flex items-center">
                                <Controller
                                  render={({ field }) => (
                                    <CircularCheckbox
                                      {...field}
                                      id={index}
                                      checked={
                                        watch('transitionId') === transition.id
                                      }
                                      disabled={
                                        !userInfo ||
                                        userInfo?.userType === 'normalUser' ||
                                        userInfo?.userType === 'lightUser'
                                      }
                                      onChange={() =>
                                        setValue('transitionId', transition.id)
                                      }
                                      label={`0${index + 1}`}
                                    />
                                  )}
                                  name="transitionId"
                                  control={control}
                                />
                                <div className="flex items-start h-full  pt-1 ml-[2px] mr-[8px]">
                                  <PlanTag
                                    planName={translatePlanName(
                                      transition?.planName
                                    )}
                                    className=""
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </CSSTransition>
              <div className="flex items-center justify-center">
                <button
                  className="flex items-center justify-center gap-[8px] w-full py-[12.5px] bg-[#EEF1F7] rounded-[40px]"
                  type="button"
                  onClick={toggleContent}
                >
                  <span>{isOpen ? '접기' : '펼치기'}</span>
                  <img
                    src="assets/img/icon_up_arrow.png"
                    alt="arrow"
                    className={`w-[12.8px] h-[8.25px]  transition-transform duration-500 ${
                      isOpen ? '' : 'rotate-180'
                    }`}
                  />
                </button>
              </div>
            </div>
          </ShadowBox>
          <div className="text-[16px] text-center">
            패스트컷은 사용자가 제공하는 URL에 있는 텍스트/이미지/영상을 토대로
            영상을 만들고 있으며,
            <br /> 링크 내의 텍스트나 이미지, 영상 등이 영상에 사용될 수
            있습니다.
            <br /> 이에 대한 지적재산권 침해 여부는 사용자가 확인하여야 하며,
            이에 관하여 패스트컷은 책임이 없습니다.
          </div>
          <div>
            <div className="flex flex-col justify-center items-center gap-[20px]">
              <button
                type="submit"
                className={`h-[60px] flex items-center justify-center bg-main gap-[4px] w-[343px] md:w-full border-none rounded-[30px] hover:bg-hover text-[#FFFFFF] font-bold  ${
                  userInfo && !usage && 'opacity-[0.2]'
                }`}
                disabled={
                  userInfo
                    ? (userInfo?.userInfo && usage && !usage.available) ||
                      !usage ||
                      isPending
                    : false
                }
              >
                <span>영상 생성 요청</span>
              </button>
            </div>
          </div>
        </form>

        <GuideToUse />
        {essentialCheck && (
          <Modal isModal={essentialCheck}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  🙌 필수 정보를 확인해주세요!
                </p>
                <p className="text-[14px]">{errorText}</p>
                {/* <p className="text-red-500">{errorMessage}</p> */}
              </div>
            </div>
            <div className="w-[400px] bg-main h-[60px] border-b  border-main rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[400px]"
                onClick={() => {
                  setEssentialCheck(false)
                }}
              >
                확인
              </button>
            </div>
          </Modal>
        )}
        {saveSuccessModal && (
          <Modal isModal={saveSuccessModal}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">저장완료</p>
                <div>
                  <p className="text-[14px]">
                    {usage?.usedCount && (
                      <span className=" text-main font-bold">
                        총 {Number(usage?.usedCount) + 1}개
                      </span>
                    )}{' '}
                    / {usage?.totalLimit}개
                  </p>
                  <p className="text-[14px] text-main font-bold">
                    영상 정보가 저장되었습니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[400px] bg-main h-[60px] border-b border-main rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[400px]"
                onClick={() => {
                  if (userInfo?.userType === UserType.체험) {
                    navigate('/my-videos?type=cut')
                  } else {
                    setSaveSuccessModal(false)
                    window.location.reload()
                  }
                }}
              >
                확인
              </button>
            </div>
          </Modal>
        )}
        {mobileStatus && (
          <Modal isModal={mobileStatus}>
            <div className="bg-white w-[300px] h-[150px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  스마트폰으로 접속하셨습니다.
                </p>
                <div className="flex flex-col leading-[22px]">
                  <p className="text-main font-bold">PC사용을 권장합니다.</p>
                </div>
              </div>
            </div>
            <div className="w-[300px] bg-main h-[40px] border-b border-main  rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[330px]"
                onClick={() => {
                  setMobileStatus((prev) => !prev)
                }}
              >
                확인
              </button>
            </div>
          </Modal>
        )}

        {isYoutube && (
          <Modal isModal={isYoutube}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  🚨 필수! 자동 업로드 설정을 해주세요!
                </p>
                <div className="flex flex-col leading-[22px]">
                  <p className="">
                    패스트컷 프로/프리미엄 플랜의 완벽한 사용을 위해
                    <br /> 내 유튜브 채널 현황을 파악하고 <br />
                    자동업로드가 될 수 있도록 설정 해주셔야 합니다 🙏
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[400px] bg-main h-[60px] border-b border-main rounded-b-[20px] flex justify-center items-center">
              <button
                className="text-white w-[330px]"
                onClick={() => {
                  setIsYoutube((prev) => !prev)
                  navigate('/youtube-auth')
                }}
              >
                자동업로드 설정하러 가기
              </button>
            </div>
          </Modal>
        )}

        {isThumbnailModal && (
          <Modal isModal={isThumbnailModal}>
            <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
              <div className="flex flex-col items-center justify-center h-full gap-[24px]">
                <p className="font-bold text-[18px]">
                  ✅ 썸네일 스타일을 변경하시겠어요?
                </p>
                <div className="flex flex-col leading-[22px]">
                  <p className=" text-[14px] ">
                    썸네일 스타일을 변경하시면
                    <br />
                    이미 선택하신 스타일의 썸네일에 입력된 내용은 삭제됩니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
              <button
                className=" w-[50%] h-full text-black bg-[#EEF1F7] rounded-bl-[20px] border-b border-[#EEF1F7] "
                onClick={() => {
                  setThumbnailCheckModal(false)
                }}
              >
                취소
              </button>
              <button
                className="text-white w-[50%] h-full bg-main rounded-br-[20px] border-b border-main"
                onClick={() => {
                  setValue('thumbnailId', prevThumbnail.id)
                  removeLocalStorage(LOCAL_THUMBNAIL_INFO)
                  setThumbnailInfo(THUMBNAIL_INFO)
                  handleThumbnail(prevThumbnail.index)
                }}
              >
                변경
              </button>
            </div>
          </Modal>
        )}

        {editThumbnail && (
          <Thumbnail
            editThumbnail={editThumbnail}
            setEditThumbnail={setEditThumbnail}
            userInfo={userInfo}
            thumbnailType={thumbnailType}
            setThumbnailInfo={setThumbnailInfo}
            thumbnailInfo={thumbnailInfo}
          />
        )}

        {isMobileTModal && (
          <MobileThumbnail
            isMobileTModal={isMobileTModal}
            setMobileTModal={setMobileTModal}
            userInfo={userInfo}
            thumbnailType={thumbnailType}
            setThumbnailInfo={setThumbnailInfo}
          />
        )}

        {isGuideModal && (
          <GuideModal
            isGuideModal={isGuideModal}
            setGuideModal={setGuideModal}
            userInfo={userInfo}
          />
        )}
      </div>
    </>
  )
}
