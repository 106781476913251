import ReactPixel from 'react-facebook-pixel'

export const getCookieValue = (key: string) => {
  let cookieKey = key + '='
  let result = ''
  const cookieArr = document.cookie.split(';')

  for (let i = 0; i < cookieArr.length; i++) {
    if (cookieArr[i][0] === ' ') {
      cookieArr[i] = cookieArr[i].substring(1)
    }

    if (cookieArr[i].indexOf(cookieKey) === 0) {
      result = cookieArr[i].slice(cookieKey.length, cookieArr[i].length)
      return result
    }
  }
  return result
}

export const completeRegistration = () => {
  ReactPixel.trackCustom('AD_SIGN_UP', {
    content_name: 'sign-up',
  })
  ReactPixel.track('CompleteRegistration', { content_name: 'sign-up-utm' })
}
export const signUpBtnClick = () => {
  ReactPixel.trackCustom('SIGN_UP', {
    content_name: 'sign-up',
  })
}

export const signInBtnClick = () => {
  if (getCookieValue('_fbc')) {
    ReactPixel.trackCustom('AD_SIGN_IN', {
      content_name: 'sign-in',
    })
  } else {
    ReactPixel.trackCustom('SIGN_IN', {
      content_name: 'sign-in',
    })
  }
}

export const freeExperienceBtnClick = () => {
  ReactPixel.trackCustom('FREE_EXPERIENCE', {
    content_name: `free-experience`,
  })
}
export const adFreeExperienceBtnClick = () => {
  ReactPixel.trackCustom('AD_FREE_EXPERIENCE', {
    content_name: `ad-free-experience`,
  })
}

export const longFormCreateBtnClick = () => {
  ReactPixel.trackCustom('LONG_FORM_CERATE', {
    content_name: 'long-form',
  })
}

export const shortFormCreateBtnClick = () => {
  ReactPixel.trackCustom('SHORT_FORM_CERATE', {
    content_name: 'short-form',
  })
}

export const pointPurchaseBtnClick = (type: string) => {
  ReactPixel.trackCustom('PURCHASE_POINT', {
    content_name: 'point',
  })
}

export const planPurchaseBtnClick = () => {
  ReactPixel.trackCustom('PURCHASE_PLAN', {
    content_name: 'plan',
  })
}

export const purchasePlan = () => {
  ReactPixel.track('Purchase', { content_name: `plan-utm` })
  ReactPixel.trackCustom('AD_PURCHASE_PLAN', {
    content_name: 'plan-utm',
  })
}

export const purchasePoint = () => {
  ReactPixel.track('Purchase', { content_name: `point-utm` })
  ReactPixel.trackCustom('AD_PURCHASE_POINT', {
    content_name: 'point-utm',
  })
}
