import axios from 'axios'
import API from './api'

export interface Annagio {
  category: string
  name: string
  path: string
  type: string
}
export async function getAnnagio(bool: boolean) {
  try {
    const data: Annagio[] = await API.get(`/affiliate/annagio?terms=${bool}`)

    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}

export async function getAnnagioSource(bool: boolean): Promise<string> {
  try {
    const data: string = await API.get(
      `/affiliate/annagio/source?terms=${bool}`
    )
    return data
  } catch (error) {
    // 오류 처리
    throw error
  }
}
