import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { getAnnagioSource } from '../../../service/event'

function useGetAnnagioSource() {
  return useMutation({
    mutationKey: ['get-annagio-source'],
    mutationFn: getAnnagioSource,
  })
}

export default useGetAnnagioSource
