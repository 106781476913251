import classNames from 'classnames'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { toast } from 'react-toastify'
import { Modal } from '../components/layout/Modal'
import useGetAnnagioSource from '../hooks/query/affiliate/useGetAnnagioSource'
import useGetAnnagio from '../hooks/query/affiliate/useGetAnnagio'
import { Annagio } from '../service/event'
import useUserStore from '../store/useUserStore'
import { useNavigate } from 'react-router-dom'

export const EventPage = () => {
  const { isPending: sourcePending, mutate: sourceMutate } =
    useGetAnnagioSource()
  const { isPending, mutate } = useGetAnnagio()
  const [firstCheckbox, setFirstCheckbox] = useState(false)
  const [secondCheckbox, setSecondCheckbox] = useState(false)
  const [isModal, setModal] = useState(false)
  const [isSourceModal, setSourceModal] = useState(false)
  const [annagioData, setAnnagioData] = useState<null | Annagio[]>(null)
  const userInfo = useUserStore((state: any) => state.userInfo)
  const navigate = useNavigate()

  const userCheck = () => {
    if (!userInfo) {
      navigate('/sign-up')
      return
    } else {
      return true
    }
  }
  const handleAnnagioSource = () => {
    const check = userCheck()
    if (check) {
      sourceMutate(secondCheckbox, {
        onSuccess: (data) => {
          window.open(data, '_blank')
          setSecondCheckbox((prev) => !prev)
        },
        onError: () => {
          setSecondCheckbox((prev) => !prev)
          setSourceModal((prev) => !prev)
        },
      })
    }
  }

  const handleAnnagio = () => {
    const check = userCheck()
    if (check) {
      mutate(firstCheckbox, {
        onSuccess: (data) => {
          setAnnagioData(data)
          setModal((prev) => !prev)
          setFirstCheckbox((prev) => !prev)
        },
        onError: () => {
          setSourceModal((prev) => !prev)
          setFirstCheckbox((prev) => !prev)
        },
      })
    }
  }
  const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      toast.success('copy 완료', {
        position: 'bottom-center',
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
    } catch (e) {}
  }
  return (
    <div className="pt-[45px] md:max-w-[816px] max-w-[375px] my-0 mx-auto flex flex-col items-center ">
      {/* 홈화면 첫번째 */}
      <div className="home_first flex flex-col items-center gap-[16px] w-[343px] md:w-[816px] mb-[40px] md:mb-10">
        <div className="flex flex-col items-center">
          <p
            className={classNames('font-bold text-center', {
              'text-[40px]': !isMobile,
              'text-[22px]': isMobile,
            })}
          >
            패스트컷 AI 의 <br />
            마이크로 인플루언서에게 드리는 기회
          </p>
          <p
            className={classNames(' font-[800]  text-center mt-4', {
              'text-[64px]': !isMobile,
              'leading-[76.8px]': !isMobile,
              'text-[32px]': isMobile,
              'leading-[38.4px]': isMobile,
            })}
            style={{
              background: 'linear-gradient(90deg, #6095FF, #D512E7)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {isMobile ? (
              <>
                내 채널에 상품 홍보하고
                <br /> 판매금액의 30% <br /> 획득하세요!
              </>
            ) : (
              <>
                내 채널에 상품 홍보하고
                <br /> 판매금액의 30% 획득하세요!
              </>
            )}
          </p>
        </div>
        <div>
          <img
            src="assets/img/annagio_event/fastcut_annagio_logo.png"
            alt="logo"
            width={394}
            height={45}
          />
        </div>
        <div>
          {' '}
          <img
            src="assets/img/annagio_event/1.png"
            alt="logo"
            width={816}
            height={377}
          />
        </div>
        <div>
          {' '}
          <img
            src="assets/img/annagio_event/2.jpg"
            alt="logo"
            width={816}
            height={875}
          />
        </div>
        <div
          className={classNames(
            'flex flex-col items-center w-full bg-[#E7F9F7] ',
            {
              ' rounded-[40px] p-10': !isMobile,
              ' rounded-[16.5px] px-[33.5px] py-[17px]': isMobile,
            }
          )}
        >
          {' '}
          <p
            className={classNames('font-bold  text-center', {
              'text-[40px]': !isMobile,
              'text-[24px]': isMobile,
            })}
          >
            판매 금액의 30%까지
            <br /> 혜택을 자유롭게 조정하세요!
          </p>
          <p
            className={classNames('text-main', {
              'text-base': !isMobile,
              'text-[14px]': isMobile,
            })}
          >
            구독몰에서 포인트와 할인율을 설정하세요!
          </p>
          <div className=" cursor-pointer">
            <a
              href="https://www.youtube.com/watch?v=41aXoDGIaTk"
              target="_blank"
              rel="noreferrer"
              className="flex flex-col items-center"
            >
              <img
                src="assets/img/annagio_event/3.png"
                alt="logo"
                className={classNames('', {
                  'w-[500px] h-[278px]': !isMobile,
                  'w-[200px] h-[140px]': isMobile,
                })}
              />
              <button
                className={classNames(
                  'bg-[#6880F5]  rounded-[60px]  font-bold text-white',
                  {
                    'px-[70.5px] py-[19.5px] text-[18px]': !isMobile,
                    'w-[280px] py-[12.5px] text-[12px]': isMobile,
                  }
                )}
              >
                💁🏻 상품 가격/수익률 설정 가이드 영상 보기
              </button>
            </a>
          </div>
        </div>
        <div
          className={classNames(
            'home_first flex flex-col items-center gap-4 w-full md:w-[816px]  shadow-main  bg-[#F6F8FB]',
            {
              'rounded-[40px] p-10': !isMobile,
              'rounded-[16.5px] p-[16.5px]': isMobile,
            }
          )}
        >
          <div className="flex flex-col w-full">
            <div className={classNames('flex flex-col items-center')}>
              <p
                className={classNames(' font-bold w-full text-center', {
                  'text-[40px]': !isMobile,
                  'text-[24px]': isMobile,
                })}
              >
                3종류의 상품 구성
              </p>
              <p className="text-[18px] text-main ">
                상품 소개 내용을 참고하세요!
              </p>
            </div>
          </div>
          <div
            className={classNames('flex  w-full flex-row justify-between', {})}
          >
            <div className="flex flex-col">
              <img
                src="assets/img/annagio_event/5.png"
                alt="cloud"
                width={240}
                height={240}
              />
            </div>

            <div className="flex flex-col">
              <img
                src="assets/img/annagio_event/6.png"
                alt="cloud"
                width={240}
                height={240}
              />
            </div>

            <div className="flex flex-col">
              <img
                src="assets/img/annagio_event/7.png"
                alt="cloud"
                width={240}
                height={240}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="home_first flex flex-col items-center gap-[16px] w-[343px] md:w-[816px] mb-10">
        <div>
          {' '}
          <img
            src="assets/img/annagio_event/event_img.png"
            alt="logo"
            width={816}
            height={1083}
          />
        </div>
        <div className="w-full bg-[#F6F8FB] rounded-[40px] p-8 flex flex-col gap-4">
          <div className="title ">
            <p className="font-bold text-[24px]">💁🏻 이벤트 상세 안내</p>
          </div>
          <div className="list flex flex-col gap-4 text-[18px]">
            <p className="">
              <span className="font-bold"> 🗓 참여 기간</span> : 10월 8일 ~ 추후
              공지시점까지
            </p>

            <p>
              <span className="font-bold"> 🎯 참여 자격</span> : 패스트컷 AI
              회원 + 이벤트 공유 및 카톡 친구 + 기간 내 제공된 링크를 통한 상품
              판매
            </p>
            <p>
              <span className="font-bold"> 🏆 랭킹 선정 </span>: 참여자격이
              있고, 패스트컷의 안내에 따라 수익 인증 하신 분들의 데이터를 모아
              1~3등까지 순위 선정
            </p>
            <p>
              <span className="font-bold"> 💰 수익 인증 </span>: 이벤트 기간
              만료 후 패스트컷 공식 채널과 이메일로 수익 인증 방법 안내 예정
            </p>
            <p>
              <span className="font-bold"> 💸 상품 혜택 </span>: 1등 5만캐시,
              2등 3만캐시, 3등 1만캐시
            </p>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          'home_first flex flex-col items-center gap-4 w-full md:w-[816px] mb-10 shadow-main p-10',
          {
            'rounded-[40px]': !isMobile,
          }
        )}
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-col ">
            <p
              className={classNames(' font-bold w-full', {
                'text-[40px]': !isMobile,
                'text-[24px]': isMobile,
              })}
            >
              STEP1. 본 이벤트를 공유해주시면 영상을 생성할 수 있어요
            </p>
            <p
              className="text-[18px] bg-[#F6F8FB] font-bold text-main py-2 px-5 rounded-[10px] mt-4 cursor-pointer"
              onClick={() => {
                handleCopyClipBoard(
                  'https://fastcut.fastseller.shop/event-page'
                )
              }}
            >
              {isMobile ? (
                <>
                  {' '}
                  https://
                  <br />
                  fastcut.fastseller.shop/event-
                  <br />
                  page
                </>
              ) : (
                <> https://fastcut.fastseller.shop/event-page</>
              )}
            </p>
          </div>
        </div>
        <div>
          <ul className=" list-decimal ml-5 text-[18px] text-[#5E6068]">
            <li>
              위 링크를 복사해서 운영하시는 소셜미디어(블로그, 카페 등등)에{' '}
              <span className="font-bold text-[#2E343D]">본 이벤트를 공유</span>
              하고 설명해주세요!
            </li>
            <li className="mt-2">
              이벤트를 공유하신 소셜미디어
              <span className="font-bold text-[#2E343D]">
                {' '}
                페이지의 링크를 패스트컷 카카오톡 상담 채널에{' '}
              </span>
              알려주세요!
            </li>
          </ul>
        </div>

        <div
          className={classNames(
            'flex justify-center w-full rounded-[60px] font-bold  text-[18px] items-center bg-[#FEE500] mt-4'
          )}
        >
          <a
            href="http://pf.kakao.com/_CFxfHxj/chat"
            target="_blank"
            rel="noreferrer"
            className="w-full h-[60px]"
          >
            <button className="w-full h-[60px] flex items-center justify-center  gap-[10px]">
              <img
                src="assets/img/icon_kakao_logo.png"
                alt="cloud"
                width={32}
                height={32}
              />
              패스트컷 카카오톡 채널 가기
            </button>
          </a>
        </div>
      </div>
      <div
        className={classNames(
          'home_first flex flex-col items-center gap-[16px] w-full md:w-[816px] mb-10 shadow-main p-10',
          {
            'rounded-[40px]': !isMobile,
          }
        )}
      >
        <div className="flex flex-col w-full gap-4">
          <div className={classNames('flex flex-col', { 'gap-4': isMobile })}>
            <p
              className={classNames(' font-bold w-full', {
                'text-[40px]': !isMobile,
                'text-[24px] ': isMobile,
              })}
            >
              STEP2. 이벤트를 공유하셨다면, 상품 소개 영상을 생성 요청하세요
            </p>
            <p className="text-[18px]">
              상품 소개 영상은 상품 소개 이미지 혹은 상품 소개 영상을 소스로
              하여 AI로 자동 생성되어{' '}
              <span className="font-bold text-main">
                각 상품당 숏폼과 롱폼 1개씩 중복 없이 무작위로
              </span>{' '}
              제공됩니다.{' '}
              <span className="font-bold text-main">영상 생성 요청은 1회</span>{' '}
              가능합니다. <br />
            </p>
            <p className={classNames('font-bold text-main text-[18px]', {})}>
              배경음악이나 기타 효과 등은 영상 파일 다운로드 후 자유롭게
              추가해서 사용하실 수 있습니다.{' '}
            </p>
          </div>
          <div className="p-5 bg-[#F6F8FB] rounded-[10px]">
            <p className="font-bold">롱폼 + 숏폼</p>
            <p>[지오클린] 안나지오 치약 100g 4개 + 칫솔 10개 + 칫솔 4개 증정</p>
          </div>
          <div className="p-5 bg-[#F6F8FB] rounded-[10px]">
            <p className="font-bold">롱폼 + 숏폼</p>
            <p>[지오클린] 안나지오 칫솔 10개</p>
          </div>
          <div className="p-5 bg-[#F6F8FB] rounded-[10px]">
            <p className="font-bold">롱폼 + 숏폼</p>
            <p>[지오클린] 안나지오 치약 100g 4개</p>
          </div>
        </div>
        <div className="flex flex-col bg-[#F6F8FB] border-[2px] border-[#D9DEE8] p-8 rounded-[20px] w-full gap-3 mt-4">
          <p className="font-bold text-[18px]">패스트컷 제휴정책 (안나지오)</p>
          <p>
            <ul className=" list-disc ml-5">
              <li>
                본 제휴정책은 ‘
                <a
                  className=" underline text-href "
                  target="_blank"
                  href="https://angelleague.notion.site/10eba20e97f3803d9471ec14ee7b9ef1"
                  rel="noreferrer"
                >
                  패스트컷 제휴정책
                </a>
                ’을 토대로, 본 상품의 홍보에 적용되는 정책입니다. 본 문서에
                기재되어 있지 않은 상세 사항은 ‘패스트컷 제휴정책’을 확인하시기
                바랍니다.
              </li>
              <li className="mt-2">
                제공되는 상품 관련 자료(제품설명자료, 사진, 영상, 초상권 등 각종
                자료)의 저작권은 광고주에게 있으며, 회원은 본 상품의 홍보
                목적으로만 본 자료를 사용할 수 있고, 다른 목적으로 사용할 수
                없습니다.
              </li>
              <li className="mt-2">
                홍보활동을 통하여 발생한 매출에 따른 수익금의 지급의무는
                구독몰(goodokmall.co.kr)에 있으며, 패스트컷에는 수익금
                지급의무가 없습니다.
              </li>
              <li className="mt-2">
                홍보활동을 통하여 발생한 매출에 따른 수익금은
                구독몰(goodokmall.co.kr)에서 사용할 수 있는 포인트로 지급되며,
                해당 포인트는 구독몰의 정책에 따라 구독몰에서 현금으로
                인출하거나 특정 금액(예: 10만원)에 미달되는 경우 포인트로만
                사용할 수 있습니다.
              </li>
            </ul>
          </p>
        </div>
        <div className="mt-4 w-full">
          <p className="flex items-center gap-3">
            <input
              type="checkbox"
              className="w-[20px] h-[20px]"
              checked={firstCheckbox}
              onChange={(e) => {
                setFirstCheckbox((prev) => !prev)
              }}
            />
            <span className="text-[#8F929B] text-[18px] font-[500]">
              [필수]{' '}
              <a
                className=" underline text-href "
                target="_blank"
                href="https://angelleague.notion.site/10eba20e97f3803d9471ec14ee7b9ef1"
                rel="noreferrer"
              >
                패스트컷 제휴정책
              </a>{' '}
              규정에 동의합니다 .
            </span>
          </p>
        </div>
        <div
          className={classNames(
            'flex justify-center w-full  text-white rounded-[60px] font-bold  text-[18px] items-center mt-4',
            { 'bg-[#3A8FF2]': firstCheckbox, 'bg-[#D4D9DF]': !firstCheckbox }
          )}
        >
          <button
            className="w-full h-[60px]"
            disabled={!firstCheckbox}
            onClick={handleAnnagio}
          >
            영상 생성 요청
          </button>
        </div>
      </div>

      <div
        className={classNames(
          'home_first flex flex-col items-center gap-8 w-full md:w-[816px] mb-10 shadow-main p-10',
          {
            'rounded-[40px]': !isMobile,
          }
        )}
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-col ">
            <p
              className={classNames(' font-bold w-full', {
                'text-[40px]': !isMobile,
                'text-[24px]': isMobile,
              })}
            >
              기타. 상품 소개 영상/이미지 소스 받기
            </p>
            <p className="text-[18px]">
              안나지오가 제작한 상품 소개 영상과 이미지로 직접 영상을 만드시려면
              다운로드 하세요. <br />
              <span className="font-bold text-main">
                파일 다운로드는 1회
              </span>{' '}
              가능합니다.
            </p>
          </div>
        </div>
        <div className="flex flex-col bg-[#F6F8FB] border-[2px] border-[#D9DEE8] p-8 rounded-[20px] w-full gap-3">
          <p className="font-bold text-[18px]">패스트컷 제휴정책 (안나지오)</p>
          <p>
            <ul className=" list-disc ml-5">
              <li>
                본 제휴정책은 ‘
                <a
                  className=" underline text-href "
                  target="_blank"
                  href="https://angelleague.notion.site/10eba20e97f3803d9471ec14ee7b9ef1"
                  rel="noreferrer"
                >
                  패스트컷 제휴정책
                </a>
                ’을 토대로, 본 상품의 홍보에 적용되는 정책입니다. 본 문서에
                기재되어 있지 않은 상세 사항은 ‘패스트컷 제휴정책’을 확인하시기
                바랍니다.
              </li>
              <li className="mt-2">
                제공되는 상품 관련 자료(제품설명자료, 사진, 영상, 초상권 등 각종
                자료)의 저작권은 광고주에게 있으며, 회원은 본 상품의 홍보
                목적으로만 본 자료를 사용할 수 있고, 다른 목적으로 사용할 수
                없습니다.
              </li>
              <li className="mt-2">
                홍보활동을 통하여 발생한 매출에 따른 수익금의 지급의무는
                구독몰(goodokmall.co.kr)에 있으며, 패스트컷에는 수익금
                지급의무가 없습니다.
              </li>
              <li className="mt-2">
                홍보활동을 통하여 발생한 매출에 따른 수익금은
                구독몰(goodokmall.co.kr)에서 사용할 수 있는 포인트로 지급되며,
                해당 포인트는 구독몰의 정책에 따라 구독몰에서 현금으로
                인출하거나 특정 금액(예: 10만원)에 미달되는 경우 포인트로만
                사용할 수 있습니다.
              </li>
            </ul>
          </p>
        </div>
        <div className=" w-full">
          <p className="flex items-center gap-3">
            <input
              type="checkbox"
              className="w-[20px] h-[20px]"
              onChange={(e) => {
                setSecondCheckbox((prev) => !prev)
              }}
              checked={secondCheckbox}
            />
            <span className="text-[#8F929B] text-[18px] font-[500]">
              [필수]{' '}
              <a
                className=" underline text-href "
                target="_blank"
                href="https://angelleague.notion.site/10eba20e97f3803d9471ec14ee7b9ef1"
                rel="noreferrer"
              >
                패스트컷 제휴정책
              </a>{' '}
              규정에 동의합니다 .
            </span>
          </p>
        </div>
        <div
          className={classNames(
            'flex justify-center w-full  text-white rounded-[60px] font-bold  text-[18px] items-center',
            { 'bg-[#3A8FF2]': secondCheckbox, 'bg-[#D4D9DF]': !secondCheckbox }
          )}
        >
          <button
            className=" w-full h-[60px] flex items-center justify-center  gap-[10px]"
            disabled={!secondCheckbox}
            onClick={handleAnnagioSource}
          >
            소스 파일 다운로드{' '}
            <img
              src="assets/img/icon_cloud.png"
              alt="cloud"
              width={24}
              height={24}
            />
          </button>
        </div>
      </div>

      <div
        className={classNames(
          'home_first flex flex-col items-center gap-4 w-full md:w-[816px] mb-10 shadow-main p-10',
          {
            'rounded-[40px]': !isMobile,
          }
        )}
      >
        <div className="flex flex-col w-full">
          <div className={classNames('flex flex-col ')}>
            <p
              className={classNames(' font-bold w-full', {
                'text-[40px]': !isMobile,
                'text-[24px]': isMobile,
              })}
            >
              STEP3. 상품 가격을 정하고 링크를 딴 후, 유튜브에 업로드 하세요
            </p>
            <p className="text-[18px] text-[#5E6068] mt-4 ">
              각 상품 페이지에 가서 가격을 정하고, 유튜브에 공유할 상품 링크를
              따서 유튜브 업로드시 해당 정보를 입력해주세요.
            </p>

            <p className="text-[18px] text-[#464DEE] mt-4 ">
              <a
                href="https://angelleague.notion.site/113ba20e97f38098832dd5c46fb473bf"
                target="_blank"
                rel="noreferrer"
              >{`가격 설정 및 유튜브 업로드 가이드 >>`}</a>
            </p>
          </div>
        </div>
        <div
          className={classNames('flex  w-full', {
            'flex-col items-center gap-8': isMobile,
            'flex-row justify-between  ': !isMobile,
          })}
        >
          <div className="flex flex-col">
            <a
              className="  flex flex-col items-center "
              target="_blank"
              href="https://goodokmall.co.kr/front/goods/sub_01.php?product_id=G24092013454048369040436#G24092013454048369040436"
              rel="noreferrer"
            >
              <img
                src="assets/img/annagio_event/5.png"
                alt="cloud"
                width={240}
                height={240}
              />
              <button className="bg-main text-white py-[9px] px-[17.5px] font-bold text-[18px] rounded-[60px]">{`상품 링크 따러 가기 >>`}</button>
            </a>
          </div>

          <div className="flex flex-col">
            <a
              className=" flex flex-col items-center"
              target="_blank"
              href="https://goodokmall.co.kr/front/goods/sub_01.php?product_id=G24092014535132256385863#G24092014535132256385863"
              rel="noreferrer"
            >
              <img
                src="assets/img/annagio_event/6.png"
                alt="cloud"
                width={240}
                height={240}
              />
              <button className="bg-main text-white py-[9px] px-[17.5px] font-bold text-[18px] rounded-[60px]">{`상품 링크 따러 가기 >>`}</button>
            </a>
          </div>

          <div className="flex flex-col">
            <a
              className=" text-href flex flex-col items-center "
              target="_blank"
              href="https://goodokmall.co.kr/front/goods/sub_01.php?product_id=G24092016023076768477698#G24092016023076768477698"
              rel="noreferrer"
            >
              <img
                src="assets/img/annagio_event/7.png"
                alt="cloud"
                width={240}
                height={240}
              />
              <button className="bg-main text-white py-[9px] px-[17.5px] font-bold text-[18px] rounded-[60px]">{`상품 링크 따러 가기 >>`}</button>
            </a>
          </div>
        </div>
      </div>
      {isModal && !isPending && (
        <Modal isModal={isModal}>
          <div className="bg-white w-[400px] min-h-[400px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center h-full gap-[24px]">
              <p className="text-[24px] font-bold">영상 다운로드</p>
              <p>
                영상과 기타 자료들의 다운로드 링크입니다.
                <br /> 본 링크는 가입하신 이메일로도 전송됩니다.
              </p>

              <div className="flex flex-col  gap-1">
                {annagioData?.map((el, i) => {
                  return (
                    <>
                      {' '}
                      <a
                        href={el.path}
                        target="_blank"
                        rel="noreferrer"
                        className="text-href underline "
                      >
                        {i + 1}. {el.name}
                      </a>
                    </>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="md:w-[400px] w-full bg-main h-[60px] border-b border-main rounded-b-[20px] flex justify-center items-center">
            <button
              className="text-white w-full"
              onClick={() => {
                setModal((prev) => !prev)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}

      {isSourceModal && (
        <Modal isModal={isSourceModal}>
          <div className="bg-white w-[400px] min-h-[184px] p-[20px] border-t rounded-t-[30px] ]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">
                🚨 이미 생성을 요청하셨어요!
              </p>
              <div className="flex flex-col text-[14px] ">
                <p className="">
                  이벤트 참여 및 영상 생성 요청은 <br /> 계정당 1회만
                  가능합니다! <br /> 먼저 요청하신 생성 영상은 <br />{' '}
                  <span className="text-[#464DEE] font-bold">
                    가입하신 계정 이메일로 전송
                  </span>
                  되었으니 <br /> 확인해주세요!
                </p>
              </div>
            </div>
          </div>
          <div className=" md:w-[400px] w-full bg-main h-[60px] border-b border-main rounded-b-[30px] flex justify-center items-center">
            <button
              className="text-white w-full h-full"
              onClick={() => {
                setSourceModal((prev) => !prev)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}
