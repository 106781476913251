import { Cookies } from 'react-cookie'
import { ErrorBox } from '../components/ErrorBox'
import { ThumbnailInfo } from '../components/Thumbnail'
const TEST = 'TEST'
export const CASH_INFO_FIRST = [
  '캐시는 패스트컷 서비스 내에서만 사용하실 수 있으며, 서비스 외부에서 사용하실 수 없습니다.',
  '1캐시는 1원입니다.',
  '사용자는 캐시로 패스트컷 플랜 등 패스트컷 서비스 내에서 안내에 따라 상품 또는 서비스를 구매할 수 있습니다.',
  '캐시는 보너스 캐시가 먼저 사용되고 현금 캐시가 사용됩니다. 보너스 캐시는 유효기간이 임박한 순으로 먼저 사용됩니다.',
  '캐시는 제3자에게 양도가 불가능합니다.',
  '캐시는 만 14세 미만의 아동이 구매할 수 없습니다.',
]

export const CASH_INFO_SECOND = [
  '결제 후 캐시 사용이력이 전혀 없는 경우, 결제 후 7일 이내에 환불이 가능합니다.',
  '7일이 지난 경우에는 미사용 캐시에 대하여 취소수수료(환불요청액의 10%)를 공제한 후 환불되나, 2천 캐시(2천원) 미만은 환불이 불가능합니다. 단, 취소수수료가 2천원보다 작은 경우에는 2천원을 취소수수료로 공제합니다.',
  '캐시는 결제 후 2년간 미사용한 경우 기간 만료로 소멸됩니다.',
  '구매한 캐시가 아닌 보너스로 지급된 캐시는 현금으로 환불되지 않습니다.',
]
export enum UserType {
  USER = 'normalUser',
  라이트 = 'lightUser',
  프로 = 'proUser',
  프리미엄 = 'premiumUser',
  체험 = 'experienceUser',
}

export const PREV_THUMBNAIL_INIT = { id: 0, index: 0 }
export const THUMBNAILINFO_LIST = 't_list'
export const LOCAL_THUMBNAIL_INFO = 'local_t'
export const THUMBNAIL_INFO: ThumbnailInfo = {
  channelName: '',
  title1: '',
  title2: '',
  title3: '',
}
export const saveLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    console.log('::::: Error save local storage :::::', err)
  }
}

export const getLocalStorage = (key: string) => {
  try {
    const items = localStorage.getItem(key)
    if (items) {
      return JSON.parse(items)
    }
  } catch (err) {
    console.log('::::: Error get local storage :::::', err)
    return null
  }
}

export const getUtmLocalStorage = () => {
  try {
    const utm_source = localStorage.getItem('utm_source')
    const utm_campaign = localStorage.getItem('utm_campaign')
    if (utm_source && utm_campaign) {
      return {
        utmSource: JSON.parse(utm_source),
        utmCampaign: JSON.parse(utm_campaign),
        trackType: '',
      }
    }
  } catch (err) {
    console.log('::::: Error get local storage :::::', err)
    return null
  }
}
export const removeLocalStorage = (key: string) => {
  try {
    localStorage.removeItem(key)
  } catch (err) {
    console.log('::::: Error remove local storage :::::', err)
  }
}

export const showErrorBox = (userInfo: any) => {
  if (!userInfo) {
    return
  }
  if (userInfo?.userInfo) {
    const { userType, userInfo: user } = userInfo
    const { youtubeAccount, youtubeChannel } = user

    if (
      (userType === UserType.프로 ||
        userType === UserType.프리미엄 ||
        userType === UserType.체험) &&
      !youtubeAccount
    ) {
      return (
        <>
          <ErrorBox />
        </>
      )
    } else if (
      (userType === UserType.프로 ||
        userType === UserType.프리미엄 ||
        userType === UserType.체험) &&
      youtubeAccount
    ) {
      if (
        !youtubeChannel[0]?.youtubeAuthorityFlag ||
        !youtubeChannel[0].advancedFlag
      ) {
        return (
          <>
            <ErrorBox />
          </>
        )
      }
    }
  }
}

const cookies = new Cookies()
export const setCookie = (
  name: string,
  value: string,
  exDay: number,
  options?: any
) => {
  const expires = new Date()
  expires.setDate(expires.getDate() + exDay) // 만료일 설정
  //expires.setSeconds(expires.getSeconds() + 30) // 30초 동안 유효
  cookies.set(name, value, { path: '/', expires, ...options })
}

export const getCookie = (name: string) => {
  return cookies.get(name)
}

export const barList = [
  {
    name: '숏폼 만들기',
    path: 'short-video-editor',
    textMain: true,
    event: false,
  },
  { name: '롱폼 만들기', path: 'video-editor', textMain: true, event: false },
  { name: '가격 안내', path: 'price-guide', textMain: true, event: false },
  { name: '자주 묻는 질문', path: 'faq', event: false },
  { name: '제휴 이벤트', path: 'event-page', event: true },
]
