import { UserInfo } from 'os'
import React, { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { Me } from '../../service/user'
import {
  getLocalStorage,
  getUtmLocalStorage,
  UserType,
} from '../../utils/common'
import { usePostExperiencePlan } from '../../hooks/query/usePostExperiencePlan'
import useUserStore from '../../store/useUserStore'
import {
  adFreeExperienceBtnClick,
  freeExperienceBtnClick,
} from '../../utils/metaAd'
import { useSaveUtm } from '../../hooks/query/useSaveUtm'
interface GuideModalProps {
  isGuideModal: boolean
  setGuideModal: Dispatch<SetStateAction<boolean>>
  userInfo: Me
}
export const GuideModal = ({
  children,
  isGuideModal,
  setGuideModal,
  userInfo,
}: PropsWithChildren<GuideModalProps>) => {
  const navigate = useNavigate()
  const refetch = useUserStore((state: any) => state.refetch)
  const { mutate: experiencePlanMutate } = usePostExperiencePlan()
  const { mutate: saveUtmMutate } = useSaveUtm()
  const paymentFreePlan = () => {
    if (!userInfo) {
      navigate('/sign-up')
      return
    } else if (userInfo?.userType === UserType.USER) {
      const utmData = getUtmLocalStorage()
      if (utmData?.utmSource) {
        utmData.trackType = 'FREE_EXPERIENCE'
        saveUtmMutate(utmData, {
          onSuccess: () => {},
          onError: () => {},
        })
        adFreeExperienceBtnClick()
      } else {
        freeExperienceBtnClick()
      }

      experiencePlanMutate('', {
        onSuccess: (data) => {
          setGuideModal(false)
          refetch()
          navigate('/video-editor?time=1')
        },
        onError: () => {
          setGuideModal(false)
        },
      })
    } else if (userInfo?.userType === UserType.체험) {
      setGuideModal(false)
    }
  }

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
      <div className="flex flex-col overflow-y-auto pb-[200px]">
        <div className="md:w-[602px] flex flex-col bg-white px-[20px] py-[32px] gap-[24px] rounded-t-[20px] ">
          <div className="flex flex-col">
            <p className="text-href text-[24px]">
              🙌 체험, 어떻게 시작하나요?!
            </p>
            <p className="mt-[24px]">
              패스트컷은 쿠팡이나 알리익스프레스 상품을 리뷰하는 영상을 쉽고
              빠르게 생성하고
              <br /> 유튜브에 자동으로 등록해주는 서비스 입니다.{' '}
            </p>
          </div>
          <div className="flex flex-col bg-[#F6F8FB] p-[20px] items-start justify-center text-left ">
            <p className="font-bold">
              {' '}
              1. 쿠팡 파트너스나 알리 익스프레스 어필리에이트 계정을
              준비하셨나요? 아직 준비 전이라면 가이드를 보고 생성해보세요!{' '}
            </p>
            <p className="flex gap-2 md:text-base text-[10px] mt-[24px]">
              <img
                src="assets/img/coupang_icon.png"
                alt="coupang"
                width={24}
                height={24}
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px]"
              />
              <a
                href="https://solution.fastseller.shop/15cc8c5d-bcd4-4d50-b145-4f32bad40634"
                target="_blank"
                rel="noreferrer"
                className="md:text-base text-[10px] text-href"
              >
                {' '}
                {`쿠팡 파트너스 가이드 >`}
              </a>
            </p>
            <p className="flex gap-2 md:text-base text-[10px] items-center mt-[8px]">
              <img
                src="assets/img/ali_icon.png"
                alt="ali"
                className="md:w-[24px] md:h-[24px] w-[16px] h-[16px] "
              />
              <a
                href="https://solution.fastseller.shop/2ad66cae-7290-42eb-a3f4-83fcf3fc15c8"
                target="_blank"
                rel="noreferrer"
                className="text-href"
              >{`알리익스프레스 어필리에이트 가이드 >`}</a>
            </p>

            <p className="mt-[24px] font-bold">
              2. 상품링크, 영상 제목, 썸네일 내용, 각종 효과들을 선택하고 영상
              생성을 요청한 후 ‘사용현황' 페이지를 확인해보세요!{' '}
            </p>
            <p className="mt-[24px] font-bold">
              {' '}
              3. 영상 생성이 완료되면 ‘사용현황' 페이지에서 파일을 다운로드 하실
              수 있어요.
            </p>
            <p className="mt-[24px] font-bold">
              4. 유튜브 채널에 자동업로드 되기를 원하시면, ‘자동 업로드 설정'
              페이지에서 필요한 설정을 해주세요.
            </p>
          </div>
          <div className="flex flex-col">
            <p className="font-bold text-[24px]">
              패스트컷 AI 체험할 준비 되셨나요?
              <br /> 궁금한 점은 언제든 <br />
              카카오톡 채널에서 문의해주세요!
            </p>
            <p className="text-href mt-[8px]">
              {' '}
              <a
                href="http://pf.kakao.com/_CFxfHxj/chat"
                target="_blank"
                rel="noreferrer"
              >
                패스트컷 카카오톡 채널 {`>`}
              </a>{' '}
            </p>
          </div>
        </div>
        <div className="flex justify-between w-full bg-white rounded-b-[20px]">
          <button className="w-[50%] h-[60px] bg-[#FFDB1C] rounded-bl-[20px] font-bold">
            <a
              href="http://pf.kakao.com/_CFxfHxj/chat"
              target="_blank"
              rel="noreferrer"
              className="w-full"
            >
              {' '}
              패스트컷 카카오톡
            </a>
          </button>
          <button
            className="w-[50%] h-[60px] bg-main rounded-br-[20px] text-white font-bold "
            onClick={paymentFreePlan}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  )
}
