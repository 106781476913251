import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'
import { getAnnagio, getAnnagioSource } from '../../../service/event'

function useGetAnnagio() {
  return useMutation({
    mutationKey: ['get-annagio'],
    mutationFn: getAnnagio,
  })
}

export default useGetAnnagio
